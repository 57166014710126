export const jeyson_bd_rows = [
  {
    row: [
      "1.Informe_General_Arsa",
      "2018.xlsx",
      "2024/06/04",
      "2024/06/06",
      "Jeyson Ariza",
    ],
  },
  {
    row: [
      "1.Informe_General_Arsa",
      "2016.xlsx",
      "2024/06/04",
      "2024/06/06",
      "Jeyson Ariza",
    ],
  },
  {
    row: [
      "1.Informe_General_Arsa",
      "2017.xlsx",
      "2024/06/04",
      "2024/06/06",
      "Jeyson Ariza",
    ],
  },
  {
    row: [
      "1.Informe_General_Arsa",
      "BD_Clientes.xlsx",
      "2024/06/04",
      "2024/06/06",
      "Jeyson Ariza",
    ],
  },
  {
    row: [
      "1.Informe_General_Arsa",
      "BD_Canal.xlsx",
      "2024/06/04",
      "2024/06/06",
      "Jeyson Ariza",
    ],
  },
  {
    row: [
      "1.Informe_General_Arsa",
      "GeoportaldelDANE.xlsx",
      "2024/06/04",
      "2024/06/06",
      "Jeyson Ariza",
    ],
  },
  {
    row: [
      "1.Informe_General_Arsa",
      "BD_Productos.xlsx",
      "2024/06/04",
      "2024/06/06",
      "Jeyson Ariza",
    ],
  },
  {
    row: [
      "1.Informe_General_Arsa",
      "BD_Vendedor.xlsx",
      "2024/06/04",
      "2024/06/06",
      "Jeyson Ariza",
    ],
  },
  {
    row: [
      "2.Informe_Visitas",
      "4-Objetivos Semanales Abril.xlsx",
      "2024/06/04",
      "2024/06/06",
      "Jeyson Ariza",
    ],
  },
  {
    row: [
      "2.Informe_Visitas",
      "5-Objetivos Semanales Mayo.xlsx",
      "2024/06/04",
      "2024/06/06",
      "Jeyson Ariza",
    ],
  },
  {
    row: [
      "2.Informe_Visitas",
      "Link informe PBI.txt",
      "2024/06/04",
      "2024/06/06",
      "Jeyson Ariza",
    ],
  },
  {
    row: [
      "2.Informe_Visitas",
      "Fondo 1280x1500 Arsa BI.png",
      "2024/06/04",
      "2024/06/06",
      "Jeyson Ariza",
    ],
  },
];

export const jeyson_bd_download = [
  "https://arsabi-my.sharepoint.com/personal/jeyson_arsabi_co/_layouts/15/download.aspx?UniqueId=f7961722-cc67-47a2-bea1-20a5f8866362&Translate=false&tempauth=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBfZGlzcGxheW5hbWUiOiJHcmFwaCBFeHBsb3JlciIsImFwcGlkIjoiZGU4YmM4YjUtZDlmOS00OGIxLWE4YWQtYjc0OGRhNzI1MDY0IiwiYXVkIjoiMDAwMDAwMDMtMDAwMC0wZmYxLWNlMDAtMDAwMDAwMDAwMDAwL2Fyc2FiaS1teS5zaGFyZXBvaW50LmNvbUAzMDVmYzAyMi01MjE3LTQ5MWYtOTQyOS04MDNkZWUxNjFkZDEiLCJjYWNoZWtleSI6IjBoLmZ8bWVtYmVyc2hpcHwxMDAzMjAwMTAzYWU1MzRkQGxpdmUuY29tIiwiY2lkIjoiajRJZnVJUjNsbHdYY29WNlVYQ1VhQT09IiwiZW5kcG9pbnR1cmwiOiJldUJhcTZrR0E1SXFsNmU4KzY3MlJTYWlQUThjeXROam9VL3Z4Y2JEbGFvPSIsImVuZHBvaW50dXJsTGVuZ3RoIjoiMTQ2IiwiZXhwIjoiMTcxNzcyMDE0OCIsImZhbWlseV9uYW1lIjoiQXJzYWJpIiwiZ2l2ZW5fbmFtZSI6IkpleXNvbiIsImlwYWRkciI6IjIwLjE5MC4xNTIuMTUyIiwiaXNsb29wYmFjayI6IlRydWUiLCJpc3MiOiIwMDAwMDAwMy0wMDAwLTBmZjEtY2UwMC0wMDAwMDAwMDAwMDAiLCJuYmYiOiIxNzE3NzE2NTQ4IiwicHVpZCI6IjEwMDMyMDAxMDNBRTUzNEQiLCJzY3AiOiJteWZpbGVzLnJlYWQgYWxsZmlsZXMucmVhZCBteWZpbGVzLndyaXRlIGFsbGZpbGVzLndyaXRlIG15YXBwZm9sZGVyLndyaXRlIGFsbHNpdGVzLnJlYWQgYWxsc2l0ZXMud3JpdGUgYWxscHJvZmlsZXMucmVhZCIsInNpdGVpZCI6IlpUZG1ORGRtTkdRdE1EWXhOaTAwWVdWaUxUbGhPVEF0T1dFeU1UWTFaV05rTWpGbCIsInRpZCI6IjMwNWZjMDIyLTUyMTctNDkxZi05NDI5LTgwM2RlZTE2MWRkMSIsInR0IjoiMiIsInVwbiI6ImpleXNvbkBhcnNhYmkuY28iLCJ2ZXIiOiJoYXNoZWRwcm9vZnRva2VuIn0.7omLUBGqh-Et97vQ3sEL3uHOBBbNmZjrrSlMVgO7I-0&ApiVersion=2.0",
  "https://arsabi-my.sharepoint.com/personal/jeyson_arsabi_co/_layouts/15/download.aspx?UniqueId=c66316e7-a3be-45c5-86d8-e60d11924710&Translate=false&tempauth=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBfZGlzcGxheW5hbWUiOiJHcmFwaCBFeHBsb3JlciIsImFwcGlkIjoiZGU4YmM4YjUtZDlmOS00OGIxLWE4YWQtYjc0OGRhNzI1MDY0IiwiYXVkIjoiMDAwMDAwMDMtMDAwMC0wZmYxLWNlMDAtMDAwMDAwMDAwMDAwL2Fyc2FiaS1teS5zaGFyZXBvaW50LmNvbUAzMDVmYzAyMi01MjE3LTQ5MWYtOTQyOS04MDNkZWUxNjFkZDEiLCJjYWNoZWtleSI6IjBoLmZ8bWVtYmVyc2hpcHwxMDAzMjAwMTAzYWU1MzRkQGxpdmUuY29tIiwiY2lkIjoiajRJZnVJUjNsbHdYY29WNlVYQ1VhQT09IiwiZW5kcG9pbnR1cmwiOiJKSjFGSXU3QkNIQTdjTUp0R2tSSk9wd20vaThDUjBJdThvTlV5NUg3NDhRPSIsImVuZHBvaW50dXJsTGVuZ3RoIjoiMTQ2IiwiZXhwIjoiMTcxNzcyMDE0OCIsImZhbWlseV9uYW1lIjoiQXJzYWJpIiwiZ2l2ZW5fbmFtZSI6IkpleXNvbiIsImlwYWRkciI6IjIwLjE5MC4xNTIuMTUyIiwiaXNsb29wYmFjayI6IlRydWUiLCJpc3MiOiIwMDAwMDAwMy0wMDAwLTBmZjEtY2UwMC0wMDAwMDAwMDAwMDAiLCJuYmYiOiIxNzE3NzE2NTQ4IiwicHVpZCI6IjEwMDMyMDAxMDNBRTUzNEQiLCJzY3AiOiJteWZpbGVzLnJlYWQgYWxsZmlsZXMucmVhZCBteWZpbGVzLndyaXRlIGFsbGZpbGVzLndyaXRlIG15YXBwZm9sZGVyLndyaXRlIGFsbHNpdGVzLnJlYWQgYWxsc2l0ZXMud3JpdGUgYWxscHJvZmlsZXMucmVhZCIsInNpdGVpZCI6IlpUZG1ORGRtTkdRdE1EWXhOaTAwWVdWaUxUbGhPVEF0T1dFeU1UWTFaV05rTWpGbCIsInRpZCI6IjMwNWZjMDIyLTUyMTctNDkxZi05NDI5LTgwM2RlZTE2MWRkMSIsInR0IjoiMiIsInVwbiI6ImpleXNvbkBhcnNhYmkuY28iLCJ2ZXIiOiJoYXNoZWRwcm9vZnRva2VuIn0.9aYa56BJHcaSOsqs0pIrn8aJaLKtUjATvzoxfIKnEZE&ApiVersion=2.0",
  "https://arsabi-my.sharepoint.com/personal/jeyson_arsabi_co/_layouts/15/download.aspx?UniqueId=9df37a47-a57e-4fcf-bcde-74c5ebd9d5dc&Translate=false&tempauth=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBfZGlzcGxheW5hbWUiOiJHcmFwaCBFeHBsb3JlciIsImFwcGlkIjoiZGU4YmM4YjUtZDlmOS00OGIxLWE4YWQtYjc0OGRhNzI1MDY0IiwiYXVkIjoiMDAwMDAwMDMtMDAwMC0wZmYxLWNlMDAtMDAwMDAwMDAwMDAwL2Fyc2FiaS1teS5zaGFyZXBvaW50LmNvbUAzMDVmYzAyMi01MjE3LTQ5MWYtOTQyOS04MDNkZWUxNjFkZDEiLCJjYWNoZWtleSI6IjBoLmZ8bWVtYmVyc2hpcHwxMDAzMjAwMTAzYWU1MzRkQGxpdmUuY29tIiwiY2lkIjoiajRJZnVJUjNsbHdYY29WNlVYQ1VhQT09IiwiZW5kcG9pbnR1cmwiOiJSN0tzQ2h4Zk90d0xxbFRkRXlBanFpUFVnTHpNZDBtVjNQaVJrU3RsY0w4PSIsImVuZHBvaW50dXJsTGVuZ3RoIjoiMTQ2IiwiZXhwIjoiMTcxNzcyMDE0OCIsImZhbWlseV9uYW1lIjoiQXJzYWJpIiwiZ2l2ZW5fbmFtZSI6IkpleXNvbiIsImlwYWRkciI6IjIwLjE5MC4xNTIuMTUyIiwiaXNsb29wYmFjayI6IlRydWUiLCJpc3MiOiIwMDAwMDAwMy0wMDAwLTBmZjEtY2UwMC0wMDAwMDAwMDAwMDAiLCJuYmYiOiIxNzE3NzE2NTQ4IiwicHVpZCI6IjEwMDMyMDAxMDNBRTUzNEQiLCJzY3AiOiJteWZpbGVzLnJlYWQgYWxsZmlsZXMucmVhZCBteWZpbGVzLndyaXRlIGFsbGZpbGVzLndyaXRlIG15YXBwZm9sZGVyLndyaXRlIGFsbHNpdGVzLnJlYWQgYWxsc2l0ZXMud3JpdGUgYWxscHJvZmlsZXMucmVhZCIsInNpdGVpZCI6IlpUZG1ORGRtTkdRdE1EWXhOaTAwWVdWaUxUbGhPVEF0T1dFeU1UWTFaV05rTWpGbCIsInRpZCI6IjMwNWZjMDIyLTUyMTctNDkxZi05NDI5LTgwM2RlZTE2MWRkMSIsInR0IjoiMiIsInVwbiI6ImpleXNvbkBhcnNhYmkuY28iLCJ2ZXIiOiJoYXNoZWRwcm9vZnRva2VuIn0.-n4a7cxCvDDOyfCoH1nMRcQ8EH3TG9-n9gGmPxVDwh8&ApiVersion=2.0",
  "https://arsabi-my.sharepoint.com/personal/jeyson_arsabi_co/_layouts/15/download.aspx?UniqueId=62f4bd31-5553-4432-88c8-5039436c37de&Translate=false&tempauth=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBfZGlzcGxheW5hbWUiOiJHcmFwaCBFeHBsb3JlciIsImFwcGlkIjoiZGU4YmM4YjUtZDlmOS00OGIxLWE4YWQtYjc0OGRhNzI1MDY0IiwiYXVkIjoiMDAwMDAwMDMtMDAwMC0wZmYxLWNlMDAtMDAwMDAwMDAwMDAwL2Fyc2FiaS1teS5zaGFyZXBvaW50LmNvbUAzMDVmYzAyMi01MjE3LTQ5MWYtOTQyOS04MDNkZWUxNjFkZDEiLCJjYWNoZWtleSI6IjBoLmZ8bWVtYmVyc2hpcHwxMDAzMjAwMTAzYWU1MzRkQGxpdmUuY29tIiwiY2lkIjoiajRJZnVJUjNsbHdYY29WNlVYQ1VhQT09IiwiZW5kcG9pbnR1cmwiOiJmVkVkN3Jvd1dsWGJMeC9BOXNPUlNVVnhsQnFUL1RGYlQwekQ0cFJJbkVnPSIsImVuZHBvaW50dXJsTGVuZ3RoIjoiMTQ2IiwiZXhwIjoiMTcxNzcyMDE0OCIsImZhbWlseV9uYW1lIjoiQXJzYWJpIiwiZ2l2ZW5fbmFtZSI6IkpleXNvbiIsImlwYWRkciI6IjIwLjE5MC4xNTIuMTUyIiwiaXNsb29wYmFjayI6IlRydWUiLCJpc3MiOiIwMDAwMDAwMy0wMDAwLTBmZjEtY2UwMC0wMDAwMDAwMDAwMDAiLCJuYmYiOiIxNzE3NzE2NTQ4IiwicHVpZCI6IjEwMDMyMDAxMDNBRTUzNEQiLCJzY3AiOiJteWZpbGVzLnJlYWQgYWxsZmlsZXMucmVhZCBteWZpbGVzLndyaXRlIGFsbGZpbGVzLndyaXRlIG15YXBwZm9sZGVyLndyaXRlIGFsbHNpdGVzLnJlYWQgYWxsc2l0ZXMud3JpdGUgYWxscHJvZmlsZXMucmVhZCIsInNpdGVpZCI6IlpUZG1ORGRtTkdRdE1EWXhOaTAwWVdWaUxUbGhPVEF0T1dFeU1UWTFaV05rTWpGbCIsInRpZCI6IjMwNWZjMDIyLTUyMTctNDkxZi05NDI5LTgwM2RlZTE2MWRkMSIsInR0IjoiMiIsInVwbiI6ImpleXNvbkBhcnNhYmkuY28iLCJ2ZXIiOiJoYXNoZWRwcm9vZnRva2VuIn0.jq8c0Rza2AGbHrFOrpxuBvGYMmbPbgsncH-ZqfRhqqE&ApiVersion=2.0",
  "https://arsabi-my.sharepoint.com/personal/jeyson_arsabi_co/_layouts/15/download.aspx?UniqueId=43afc8e3-e8d0-434b-96a4-df7aaadad54b&Translate=false&tempauth=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBfZGlzcGxheW5hbWUiOiJHcmFwaCBFeHBsb3JlciIsImFwcGlkIjoiZGU4YmM4YjUtZDlmOS00OGIxLWE4YWQtYjc0OGRhNzI1MDY0IiwiYXVkIjoiMDAwMDAwMDMtMDAwMC0wZmYxLWNlMDAtMDAwMDAwMDAwMDAwL2Fyc2FiaS1teS5zaGFyZXBvaW50LmNvbUAzMDVmYzAyMi01MjE3LTQ5MWYtOTQyOS04MDNkZWUxNjFkZDEiLCJjYWNoZWtleSI6IjBoLmZ8bWVtYmVyc2hpcHwxMDAzMjAwMTAzYWU1MzRkQGxpdmUuY29tIiwiY2lkIjoiajRJZnVJUjNsbHdYY29WNlVYQ1VhQT09IiwiZW5kcG9pbnR1cmwiOiJYdUthNXJRM3FxUi8vMm1MVGxLSWlPVnpmS3A5RWJFcnFaQ2YwdnhyNzNzPSIsImVuZHBvaW50dXJsTGVuZ3RoIjoiMTQ2IiwiZXhwIjoiMTcxNzcyMDE0OCIsImZhbWlseV9uYW1lIjoiQXJzYWJpIiwiZ2l2ZW5fbmFtZSI6IkpleXNvbiIsImlwYWRkciI6IjIwLjE5MC4xNTIuMTUyIiwiaXNsb29wYmFjayI6IlRydWUiLCJpc3MiOiIwMDAwMDAwMy0wMDAwLTBmZjEtY2UwMC0wMDAwMDAwMDAwMDAiLCJuYmYiOiIxNzE3NzE2NTQ4IiwicHVpZCI6IjEwMDMyMDAxMDNBRTUzNEQiLCJzY3AiOiJteWZpbGVzLnJlYWQgYWxsZmlsZXMucmVhZCBteWZpbGVzLndyaXRlIGFsbGZpbGVzLndyaXRlIG15YXBwZm9sZGVyLndyaXRlIGFsbHNpdGVzLnJlYWQgYWxsc2l0ZXMud3JpdGUgYWxscHJvZmlsZXMucmVhZCIsInNpdGVpZCI6IlpUZG1ORGRtTkdRdE1EWXhOaTAwWVdWaUxUbGhPVEF0T1dFeU1UWTFaV05rTWpGbCIsInRpZCI6IjMwNWZjMDIyLTUyMTctNDkxZi05NDI5LTgwM2RlZTE2MWRkMSIsInR0IjoiMiIsInVwbiI6ImpleXNvbkBhcnNhYmkuY28iLCJ2ZXIiOiJoYXNoZWRwcm9vZnRva2VuIn0.DlgEfKssX8vnzo6xfLA6yzC7ZQDBZGbXFxHkHJILisw&ApiVersion=2.0",
  "https://arsabi-my.sharepoint.com/personal/jeyson_arsabi_co/_layouts/15/download.aspx?UniqueId=da8d1ca9-cf8c-4d27-babc-ce8f98ca5d8a&Translate=false&tempauth=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBfZGlzcGxheW5hbWUiOiJHcmFwaCBFeHBsb3JlciIsImFwcGlkIjoiZGU4YmM4YjUtZDlmOS00OGIxLWE4YWQtYjc0OGRhNzI1MDY0IiwiYXVkIjoiMDAwMDAwMDMtMDAwMC0wZmYxLWNlMDAtMDAwMDAwMDAwMDAwL2Fyc2FiaS1teS5zaGFyZXBvaW50LmNvbUAzMDVmYzAyMi01MjE3LTQ5MWYtOTQyOS04MDNkZWUxNjFkZDEiLCJjYWNoZWtleSI6IjBoLmZ8bWVtYmVyc2hpcHwxMDAzMjAwMTAzYWU1MzRkQGxpdmUuY29tIiwiY2lkIjoiajRJZnVJUjNsbHdYY29WNlVYQ1VhQT09IiwiZW5kcG9pbnR1cmwiOiJUZTAvU3RaUHB3QldFQzBQc2pUWEtsRU55ZFY5VHhBcVlNcnh4cFhyRzkwPSIsImVuZHBvaW50dXJsTGVuZ3RoIjoiMTQ2IiwiZXhwIjoiMTcxNzcyMDE0OCIsImZhbWlseV9uYW1lIjoiQXJzYWJpIiwiZ2l2ZW5fbmFtZSI6IkpleXNvbiIsImlwYWRkciI6IjIwLjE5MC4xNTIuMTUyIiwiaXNsb29wYmFjayI6IlRydWUiLCJpc3MiOiIwMDAwMDAwMy0wMDAwLTBmZjEtY2UwMC0wMDAwMDAwMDAwMDAiLCJuYmYiOiIxNzE3NzE2NTQ4IiwicHVpZCI6IjEwMDMyMDAxMDNBRTUzNEQiLCJzY3AiOiJteWZpbGVzLnJlYWQgYWxsZmlsZXMucmVhZCBteWZpbGVzLndyaXRlIGFsbGZpbGVzLndyaXRlIG15YXBwZm9sZGVyLndyaXRlIGFsbHNpdGVzLnJlYWQgYWxsc2l0ZXMud3JpdGUgYWxscHJvZmlsZXMucmVhZCIsInNpdGVpZCI6IlpUZG1ORGRtTkdRdE1EWXhOaTAwWVdWaUxUbGhPVEF0T1dFeU1UWTFaV05rTWpGbCIsInRpZCI6IjMwNWZjMDIyLTUyMTctNDkxZi05NDI5LTgwM2RlZTE2MWRkMSIsInR0IjoiMiIsInVwbiI6ImpleXNvbkBhcnNhYmkuY28iLCJ2ZXIiOiJoYXNoZWRwcm9vZnRva2VuIn0.8HvbmS4ndBcu0qEWLDByFidI2b_U_zSOpaP78yisUhU&ApiVersion=2.0",
  "https://arsabi-my.sharepoint.com/personal/jeyson_arsabi_co/_layouts/15/download.aspx?UniqueId=d112f2ed-8164-4ba0-aa7c-7270ff71adea&Translate=false&tempauth=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBfZGlzcGxheW5hbWUiOiJHcmFwaCBFeHBsb3JlciIsImFwcGlkIjoiZGU4YmM4YjUtZDlmOS00OGIxLWE4YWQtYjc0OGRhNzI1MDY0IiwiYXVkIjoiMDAwMDAwMDMtMDAwMC0wZmYxLWNlMDAtMDAwMDAwMDAwMDAwL2Fyc2FiaS1teS5zaGFyZXBvaW50LmNvbUAzMDVmYzAyMi01MjE3LTQ5MWYtOTQyOS04MDNkZWUxNjFkZDEiLCJjYWNoZWtleSI6IjBoLmZ8bWVtYmVyc2hpcHwxMDAzMjAwMTAzYWU1MzRkQGxpdmUuY29tIiwiY2lkIjoiajRJZnVJUjNsbHdYY29WNlVYQ1VhQT09IiwiZW5kcG9pbnR1cmwiOiJYbGlBWlpXdFEwQjRtd0JUcmY5ME9qK0xSbGZLemhZYUdEd21sTHZzdDJjPSIsImVuZHBvaW50dXJsTGVuZ3RoIjoiMTQ2IiwiZXhwIjoiMTcxNzcyMDE0OCIsImZhbWlseV9uYW1lIjoiQXJzYWJpIiwiZ2l2ZW5fbmFtZSI6IkpleXNvbiIsImlwYWRkciI6IjIwLjE5MC4xNTIuMTUyIiwiaXNsb29wYmFjayI6IlRydWUiLCJpc3MiOiIwMDAwMDAwMy0wMDAwLTBmZjEtY2UwMC0wMDAwMDAwMDAwMDAiLCJuYmYiOiIxNzE3NzE2NTQ4IiwicHVpZCI6IjEwMDMyMDAxMDNBRTUzNEQiLCJzY3AiOiJteWZpbGVzLnJlYWQgYWxsZmlsZXMucmVhZCBteWZpbGVzLndyaXRlIGFsbGZpbGVzLndyaXRlIG15YXBwZm9sZGVyLndyaXRlIGFsbHNpdGVzLnJlYWQgYWxsc2l0ZXMud3JpdGUgYWxscHJvZmlsZXMucmVhZCIsInNpdGVpZCI6IlpUZG1ORGRtTkdRdE1EWXhOaTAwWVdWaUxUbGhPVEF0T1dFeU1UWTFaV05rTWpGbCIsInRpZCI6IjMwNWZjMDIyLTUyMTctNDkxZi05NDI5LTgwM2RlZTE2MWRkMSIsInR0IjoiMiIsInVwbiI6ImpleXNvbkBhcnNhYmkuY28iLCJ2ZXIiOiJoYXNoZWRwcm9vZnRva2VuIn0.gVgNy0QiOmOr9sOyxgwoKhGFE6bWKmRaCeOkbF42Kkg&ApiVersion=2.0",
  "https://arsabi-my.sharepoint.com/personal/jeyson_arsabi_co/_layouts/15/download.aspx?UniqueId=8094733a-843f-4f6a-9915-0f731e9b509f&Translate=false&tempauth=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBfZGlzcGxheW5hbWUiOiJHcmFwaCBFeHBsb3JlciIsImFwcGlkIjoiZGU4YmM4YjUtZDlmOS00OGIxLWE4YWQtYjc0OGRhNzI1MDY0IiwiYXVkIjoiMDAwMDAwMDMtMDAwMC0wZmYxLWNlMDAtMDAwMDAwMDAwMDAwL2Fyc2FiaS1teS5zaGFyZXBvaW50LmNvbUAzMDVmYzAyMi01MjE3LTQ5MWYtOTQyOS04MDNkZWUxNjFkZDEiLCJjYWNoZWtleSI6IjBoLmZ8bWVtYmVyc2hpcHwxMDAzMjAwMTAzYWU1MzRkQGxpdmUuY29tIiwiY2lkIjoiajRJZnVJUjNsbHdYY29WNlVYQ1VhQT09IiwiZW5kcG9pbnR1cmwiOiI1dERSYnJVQ1dkc3ZGenl0MkdQcXVDTDZsOVM1OEVMZGtTTDRCUVRjQzRNPSIsImVuZHBvaW50dXJsTGVuZ3RoIjoiMTQ2IiwiZXhwIjoiMTcxNzcyMDE0OCIsImZhbWlseV9uYW1lIjoiQXJzYWJpIiwiZ2l2ZW5fbmFtZSI6IkpleXNvbiIsImlwYWRkciI6IjIwLjE5MC4xNTIuMTUyIiwiaXNsb29wYmFjayI6IlRydWUiLCJpc3MiOiIwMDAwMDAwMy0wMDAwLTBmZjEtY2UwMC0wMDAwMDAwMDAwMDAiLCJuYmYiOiIxNzE3NzE2NTQ4IiwicHVpZCI6IjEwMDMyMDAxMDNBRTUzNEQiLCJzY3AiOiJteWZpbGVzLnJlYWQgYWxsZmlsZXMucmVhZCBteWZpbGVzLndyaXRlIGFsbGZpbGVzLndyaXRlIG15YXBwZm9sZGVyLndyaXRlIGFsbHNpdGVzLnJlYWQgYWxsc2l0ZXMud3JpdGUgYWxscHJvZmlsZXMucmVhZCIsInNpdGVpZCI6IlpUZG1ORGRtTkdRdE1EWXhOaTAwWVdWaUxUbGhPVEF0T1dFeU1UWTFaV05rTWpGbCIsInRpZCI6IjMwNWZjMDIyLTUyMTctNDkxZi05NDI5LTgwM2RlZTE2MWRkMSIsInR0IjoiMiIsInVwbiI6ImpleXNvbkBhcnNhYmkuY28iLCJ2ZXIiOiJoYXNoZWRwcm9vZnRva2VuIn0.5KJhUHADxchGoLNIOq3IdGVQhaqLi7491bdW1ZK14sQ&ApiVersion=2.0",
  "https://arsabi-my.sharepoint.com/personal/jeyson_arsabi_co/_layouts/15/download.aspx?UniqueId=7bd12fa2-9013-42d6-b82c-72a565a48005&Translate=false&tempauth=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBfZGlzcGxheW5hbWUiOiJHcmFwaCBFeHBsb3JlciIsImFwcGlkIjoiZGU4YmM4YjUtZDlmOS00OGIxLWE4YWQtYjc0OGRhNzI1MDY0IiwiYXVkIjoiMDAwMDAwMDMtMDAwMC0wZmYxLWNlMDAtMDAwMDAwMDAwMDAwL2Fyc2FiaS1teS5zaGFyZXBvaW50LmNvbUAzMDVmYzAyMi01MjE3LTQ5MWYtOTQyOS04MDNkZWUxNjFkZDEiLCJjYWNoZWtleSI6IjBoLmZ8bWVtYmVyc2hpcHwxMDAzMjAwMTAzYWU1MzRkQGxpdmUuY29tIiwiY2lkIjoieTVlK0l4V0V1RzhNWW9rNVExVXRlQT09IiwiZW5kcG9pbnR1cmwiOiIreFFtYkZJbE9QV3NUQXV5NVAzaEg0NEJ0ZDQwRS9YMjlwVEtQSkw1dnRFPSIsImVuZHBvaW50dXJsTGVuZ3RoIjoiMTQ2IiwiZXhwIjoiMTcxNzcyMDY3NCIsImZhbWlseV9uYW1lIjoiQXJzYWJpIiwiZ2l2ZW5fbmFtZSI6IkpleXNvbiIsImlwYWRkciI6IjQwLjEyNi4yNC4xNTIiLCJpc2xvb3BiYWNrIjoiVHJ1ZSIsImlzcyI6IjAwMDAwMDAzLTAwMDAtMGZmMS1jZTAwLTAwMDAwMDAwMDAwMCIsIm5iZiI6IjE3MTc3MTcwNzQiLCJwdWlkIjoiMTAwMzIwMDEwM0FFNTM0RCIsInNjcCI6Im15ZmlsZXMucmVhZCBhbGxmaWxlcy5yZWFkIG15ZmlsZXMud3JpdGUgYWxsZmlsZXMud3JpdGUgbXlhcHBmb2xkZXIud3JpdGUgYWxsc2l0ZXMucmVhZCBhbGxzaXRlcy53cml0ZSBhbGxwcm9maWxlcy5yZWFkIiwic2l0ZWlkIjoiWlRkbU5EZG1OR1F0TURZeE5pMDBZV1ZpTFRsaE9UQXRPV0V5TVRZMVpXTmtNakZsIiwidGlkIjoiMzA1ZmMwMjItNTIxNy00OTFmLTk0MjktODAzZGVlMTYxZGQxIiwidHQiOiIyIiwidXBuIjoiamV5c29uQGFyc2FiaS5jbyIsInZlciI6Imhhc2hlZHByb29mdG9rZW4ifQ.vzcam-SyntCwFwuSivnjwbPMTcWVdFgXGIyychi0A94&ApiVersion=2.0",
  "https://arsabi-my.sharepoint.com/personal/jeyson_arsabi_co/_layouts/15/download.aspx?UniqueId=1e787a38-e6e6-4a0b-b8c7-c9f731e0c64c&Translate=false&tempauth=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBfZGlzcGxheW5hbWUiOiJHcmFwaCBFeHBsb3JlciIsImFwcGlkIjoiZGU4YmM4YjUtZDlmOS00OGIxLWE4YWQtYjc0OGRhNzI1MDY0IiwiYXVkIjoiMDAwMDAwMDMtMDAwMC0wZmYxLWNlMDAtMDAwMDAwMDAwMDAwL2Fyc2FiaS1teS5zaGFyZXBvaW50LmNvbUAzMDVmYzAyMi01MjE3LTQ5MWYtOTQyOS04MDNkZWUxNjFkZDEiLCJjYWNoZWtleSI6IjBoLmZ8bWVtYmVyc2hpcHwxMDAzMjAwMTAzYWU1MzRkQGxpdmUuY29tIiwiY2lkIjoieTVlK0l4V0V1RzhNWW9rNVExVXRlQT09IiwiZW5kcG9pbnR1cmwiOiJPL0dxMnVCV0lmSk5DMWtiNXBWdXVLcXNySnVqYnNXUzhYZ0ZtOUlzaURZPSIsImVuZHBvaW50dXJsTGVuZ3RoIjoiMTQ2IiwiZXhwIjoiMTcxNzcyMDY3NCIsImZhbWlseV9uYW1lIjoiQXJzYWJpIiwiZ2l2ZW5fbmFtZSI6IkpleXNvbiIsImlwYWRkciI6IjQwLjEyNi4yNC4xNTIiLCJpc2xvb3BiYWNrIjoiVHJ1ZSIsImlzcyI6IjAwMDAwMDAzLTAwMDAtMGZmMS1jZTAwLTAwMDAwMDAwMDAwMCIsIm5iZiI6IjE3MTc3MTcwNzQiLCJwdWlkIjoiMTAwMzIwMDEwM0FFNTM0RCIsInNjcCI6Im15ZmlsZXMucmVhZCBhbGxmaWxlcy5yZWFkIG15ZmlsZXMud3JpdGUgYWxsZmlsZXMud3JpdGUgbXlhcHBmb2xkZXIud3JpdGUgYWxsc2l0ZXMucmVhZCBhbGxzaXRlcy53cml0ZSBhbGxwcm9maWxlcy5yZWFkIiwic2l0ZWlkIjoiWlRkbU5EZG1OR1F0TURZeE5pMDBZV1ZpTFRsaE9UQXRPV0V5TVRZMVpXTmtNakZsIiwidGlkIjoiMzA1ZmMwMjItNTIxNy00OTFmLTk0MjktODAzZGVlMTYxZGQxIiwidHQiOiIyIiwidXBuIjoiamV5c29uQGFyc2FiaS5jbyIsInZlciI6Imhhc2hlZHByb29mdG9rZW4ifQ.EUGW7yQqbI0hsI2dys1UPRspUgkQ3UEJHa13GSF58x0&ApiVersion=2.0",
  "https://arsabi-my.sharepoint.com/personal/jeyson_arsabi_co/_layouts/15/download.aspx?UniqueId=3823ea45-47bb-468d-b4da-21f033e2dc53&Translate=false&tempauth=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBfZGlzcGxheW5hbWUiOiJHcmFwaCBFeHBsb3JlciIsImFwcGlkIjoiZGU4YmM4YjUtZDlmOS00OGIxLWE4YWQtYjc0OGRhNzI1MDY0IiwiYXVkIjoiMDAwMDAwMDMtMDAwMC0wZmYxLWNlMDAtMDAwMDAwMDAwMDAwL2Fyc2FiaS1teS5zaGFyZXBvaW50LmNvbUAzMDVmYzAyMi01MjE3LTQ5MWYtOTQyOS04MDNkZWUxNjFkZDEiLCJjYWNoZWtleSI6IjBoLmZ8bWVtYmVyc2hpcHwxMDAzMjAwMTAzYWU1MzRkQGxpdmUuY29tIiwiY2lkIjoieTVlK0l4V0V1RzhNWW9rNVExVXRlQT09IiwiZW5kcG9pbnR1cmwiOiIwMUhCUUNlN05UanlMTHRReUFyTUo4cSsvOTI0YTJ1UXpKYlJQd1E5aWdVPSIsImVuZHBvaW50dXJsTGVuZ3RoIjoiMTQ2IiwiZXhwIjoiMTcxNzcyMDY3NCIsImZhbWlseV9uYW1lIjoiQXJzYWJpIiwiZ2l2ZW5fbmFtZSI6IkpleXNvbiIsImlwYWRkciI6IjQwLjEyNi4yNC4xNTIiLCJpc2xvb3BiYWNrIjoiVHJ1ZSIsImlzcyI6IjAwMDAwMDAzLTAwMDAtMGZmMS1jZTAwLTAwMDAwMDAwMDAwMCIsIm5iZiI6IjE3MTc3MTcwNzQiLCJwdWlkIjoiMTAwMzIwMDEwM0FFNTM0RCIsInNjcCI6Im15ZmlsZXMucmVhZCBhbGxmaWxlcy5yZWFkIG15ZmlsZXMud3JpdGUgYWxsZmlsZXMud3JpdGUgbXlhcHBmb2xkZXIud3JpdGUgYWxsc2l0ZXMucmVhZCBhbGxzaXRlcy53cml0ZSBhbGxwcm9maWxlcy5yZWFkIiwic2l0ZWlkIjoiWlRkbU5EZG1OR1F0TURZeE5pMDBZV1ZpTFRsaE9UQXRPV0V5TVRZMVpXTmtNakZsIiwidGlkIjoiMzA1ZmMwMjItNTIxNy00OTFmLTk0MjktODAzZGVlMTYxZGQxIiwidHQiOiIyIiwidXBuIjoiamV5c29uQGFyc2FiaS5jbyIsInZlciI6Imhhc2hlZHByb29mdG9rZW4ifQ.knhheIbu_SyQAeYVn64ZSqNfrdw7DBGi_5nlQ-Bnh0c&ApiVersion=2.0",
  "https://arsabi-my.sharepoint.com/personal/jeyson_arsabi_co/_layouts/15/download.aspx?UniqueId=f923da32-ea37-4d19-860d-188aa4461502&Translate=false&tempauth=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBfZGlzcGxheW5hbWUiOiJHcmFwaCBFeHBsb3JlciIsImFwcGlkIjoiZGU4YmM4YjUtZDlmOS00OGIxLWE4YWQtYjc0OGRhNzI1MDY0IiwiYXVkIjoiMDAwMDAwMDMtMDAwMC0wZmYxLWNlMDAtMDAwMDAwMDAwMDAwL2Fyc2FiaS1teS5zaGFyZXBvaW50LmNvbUAzMDVmYzAyMi01MjE3LTQ5MWYtOTQyOS04MDNkZWUxNjFkZDEiLCJjYWNoZWtleSI6IjBoLmZ8bWVtYmVyc2hpcHwxMDAzMjAwMTAzYWU1MzRkQGxpdmUuY29tIiwiY2lkIjoieTVlK0l4V0V1RzhNWW9rNVExVXRlQT09IiwiZW5kcG9pbnR1cmwiOiJkRGN6S29WQUJ4M3VLSS81RGY1alV1R05iZ3BZeUEwa0FQSFh1bFFBalRBPSIsImVuZHBvaW50dXJsTGVuZ3RoIjoiMTQ2IiwiZXhwIjoiMTcxNzcyMDY3NCIsImZhbWlseV9uYW1lIjoiQXJzYWJpIiwiZ2l2ZW5fbmFtZSI6IkpleXNvbiIsImlwYWRkciI6IjQwLjEyNi4yNC4xNTIiLCJpc2xvb3BiYWNrIjoiVHJ1ZSIsImlzcyI6IjAwMDAwMDAzLTAwMDAtMGZmMS1jZTAwLTAwMDAwMDAwMDAwMCIsIm5iZiI6IjE3MTc3MTcwNzQiLCJwdWlkIjoiMTAwMzIwMDEwM0FFNTM0RCIsInNjcCI6Im15ZmlsZXMucmVhZCBhbGxmaWxlcy5yZWFkIG15ZmlsZXMud3JpdGUgYWxsZmlsZXMud3JpdGUgbXlhcHBmb2xkZXIud3JpdGUgYWxsc2l0ZXMucmVhZCBhbGxzaXRlcy53cml0ZSBhbGxwcm9maWxlcy5yZWFkIiwic2l0ZWlkIjoiWlRkbU5EZG1OR1F0TURZeE5pMDBZV1ZpTFRsaE9UQXRPV0V5TVRZMVpXTmtNakZsIiwidGlkIjoiMzA1ZmMwMjItNTIxNy00OTFmLTk0MjktODAzZGVlMTYxZGQxIiwidHQiOiIyIiwidXBuIjoiamV5c29uQGFyc2FiaS5jbyIsInZlciI6Imhhc2hlZHByb29mdG9rZW4ifQ.mm_B-ShypLjnHDKljc6CDpGkCz0S1OE0mb6ziyW4pgg&ApiVersion=2.0",
];

export const jeyson_bd_view = [
  "https://arsabi-my.sharepoint.com/personal/jeyson_arsabi_co/_layouts/15/Doc.aspx?sourcedoc=%7BF7961722-CC67-47A2-BEA1-20A5F8866362%7D&file=2016.xlsx&action=default&mobileredirect=true",
  "https://arsabi-my.sharepoint.com/personal/jeyson_arsabi_co/_layouts/15/Doc.aspx?sourcedoc=%7BC66316E7-A3BE-45C5-86D8-E60D11924710%7D&file=2017.xlsx&action=default&mobileredirect=true",
  "https://arsabi-my.sharepoint.com/personal/jeyson_arsabi_co/_layouts/15/Doc.aspx?sourcedoc=%7B9DF37A47-A57E-4FCF-BCDE-74C5EBD9D5DC%7D&file=2018.xlsx&action=default&mobileredirect=true",
  "https://arsabi-my.sharepoint.com/personal/jeyson_arsabi_co/_layouts/15/Doc.aspx?sourcedoc=%7B62F4BD31-5553-4432-88C8-5039436C37DE%7D&file=BD_Canal.xlsx&action=default&mobileredirect=true",
  "https://arsabi-my.sharepoint.com/personal/jeyson_arsabi_co/_layouts/15/Doc.aspx?sourcedoc=%7B43AFC8E3-E8D0-434B-96A4-DF7AAADAD54B%7D&file=BD_Clientes.xlsx&action=default&mobileredirect=true",
  "https://arsabi-my.sharepoint.com/personal/jeyson_arsabi_co/_layouts/15/Doc.aspx?sourcedoc=%7BDA8D1CA9-CF8C-4D27-BABC-CE8F98CA5D8A%7D&file=BD_Productos.xlsx&action=default&mobileredirect=true",
  "https://arsabi-my.sharepoint.com/personal/jeyson_arsabi_co/_layouts/15/Doc.aspx?sourcedoc=%7BD112F2ED-8164-4BA0-AA7C-7270FF71ADEA%7D&file=BD_Vendedor.xlsx&action=default&mobileredirect=true",
  "https://arsabi-my.sharepoint.com/personal/jeyson_arsabi_co/_layouts/15/Doc.aspx?sourcedoc=%7B8094733A-843F-4F6A-9915-0F731E9B509F%7D&file=GeoportaldelDANE.xlsx&action=default&mobileredirect=true",
  "https://arsabi-my.sharepoint.com/personal/jeyson_arsabi_co/_layouts/15/Doc.aspx?sourcedoc=%7B5806BFFA-6014-43F5-B99B-AFAA131EA59C%7D&file=TD%20Asesores.xlsx&action=default&mobileredirect=true",
];

export const laura_bd_rows = [
  {
    row: [
      "1.Informe_Gerencial",
      "Resumen Plis BI(MovDav (Conciliación)).csv",
      "2024/06/04",
      "2024/06/04",
      "Laura Sarmiento",
    ],
  },
];

export const laura_bd_view = [
  "https://arsabi-my.sharepoint.com/personal/laura_arsabi_co/_layouts/15/Doc.aspx?sourcedoc=%7BC26C02CD-3E48-4979-87D8-1E38BD86A229%7D&file=Resumen%20Plis%20BI(MovDav%20(Conciliaci%C3%B3n)).csv&action=default&mobileredirect=true",
];

export const laura_bd_download = [
  "https://arsabi-my.sharepoint.com/personal/laura_arsabi_co/_layouts/15/download.aspx?UniqueId=c26c02cd-3e48-4979-87d8-1e38bd86a229&Translate=false&tempauth=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBfZGlzcGxheW5hbWUiOiJHcmFwaCBFeHBsb3JlciIsImFwcGlkIjoiZGU4YmM4YjUtZDlmOS00OGIxLWE4YWQtYjc0OGRhNzI1MDY0IiwiYXVkIjoiMDAwMDAwMDMtMDAwMC0wZmYxLWNlMDAtMDAwMDAwMDAwMDAwL2Fyc2FiaS1teS5zaGFyZXBvaW50LmNvbUAzMDVmYzAyMi01MjE3LTQ5MWYtOTQyOS04MDNkZWUxNjFkZDEiLCJjYWNoZWtleSI6IjBoLmZ8bWVtYmVyc2hpcHwxMDAzMjAwMTAzYWU1M2FkQGxpdmUuY29tIiwiY2lkIjoiL3FhbWRrMW5vMVlEazNRK1NLRi90dz09IiwiZW5kcG9pbnR1cmwiOiJmckkxdEN1M2E5bTJseTdjejBCWXExc0ZBdVR5QWVOSzJUQmRtbkk5akwwPSIsImVuZHBvaW50dXJsTGVuZ3RoIjoiMTQ1IiwiZXhwIjoiMTcxNzcyMTM1OCIsImZhbWlseV9uYW1lIjoiQXJzYWJpIiwiZ2l2ZW5fbmFtZSI6IkxhdXJhIiwiaXBhZGRyIjoiNDAuMTI2LjI0LjE1MiIsImlzbG9vcGJhY2siOiJUcnVlIiwiaXNzIjoiMDAwMDAwMDMtMDAwMC0wZmYxLWNlMDAtMDAwMDAwMDAwMDAwIiwibmJmIjoiMTcxNzcxNzc1OCIsInB1aWQiOiIxMDAzMjAwMTAzQUU1M0FEIiwic2NwIjoibXlmaWxlcy5yZWFkIGFsbGZpbGVzLnJlYWQgbXlmaWxlcy53cml0ZSBhbGxmaWxlcy53cml0ZSBteWFwcGZvbGRlci53cml0ZSBhbGxzaXRlcy5yZWFkIGFsbHNpdGVzLndyaXRlIGFsbHByb2ZpbGVzLnJlYWQiLCJzaWduaW5fc3RhdGUiOiJbXCJrbXNpXCJdIiwic2l0ZWlkIjoiWTJJM1lXTTFaVGN0TXpZeU5TMDBNRGcwTFRoaVlUZ3RNMlJoWXpRMU5UZzFNMkZoIiwidGlkIjoiMzA1ZmMwMjItNTIxNy00OTFmLTk0MjktODAzZGVlMTYxZGQxIiwidHQiOiIyIiwidXBuIjoibGF1cmFAYXJzYWJpLmNvIiwidmVyIjoiaGFzaGVkcHJvb2Z0b2tlbiJ9.0nOPnEJaZvC6UCpQ0p_lFuMkDc7mAz1ZhKH74DOZYko&ApiVersion=2.0",
];

export const juan_bd_rows = [
  {
    row: [
      "1.Lanuza",
      "Link Equipos.xlsx",
      "2024/06/04",
      "2024/06/06",
      "Juan Quesada",
    ],
  },
  {
    row: [
      "2.BI_Visitas",
      "TH PQRS Informes de visitas.xlsx",
      "2024/06/04",
      "2024/06/06",
      "Juan Quesada",
    ],
  },
  {
    row: [
      "2.BI_Visitas",
      "TH Informes de visitas.xlsx",
      "2024/06/04",
      "2024/06/06",
      "Juan Quesada",
    ],
  },
  {
    row: [
      "2.BI_Visitas",
      "TD Inventario DB.xlsx",
      "2024/06/04",
      "2024/06/06",
      "Juan Quesada",
    ],
  },
  {
    row: [
      "2.BI_Visitas",
      "TD Busqueda de terceros.xlsx",
      "2024/06/04",
      "2024/06/06",
      "Juan Quesada",
    ],
  },
];

export const juan_bd_view = [
  "https://arsabi-my.sharepoint.com/personal/juan_quesada_arsabi_co/_layouts/15/Doc.aspx?sourcedoc=%7B6B8C5B07-CEBF-4ABB-A9B6-EF6169840A10%7D&file=Link%20Equipos.xlsx&action=default&mobileredirect=true",
  "https://arsabi-my.sharepoint.com/personal/juan_quesada_arsabi_co/_layouts/15/Doc.aspx?sourcedoc=%7B7F593DD4-5726-4858-A98B-50BEC6DD7AD4%7D&file=TH%20PQRS%20Informes%20de%20visitas.xlsx&action=default&mobileredirect=true",
  "https://arsabi-my.sharepoint.com/personal/juan_quesada_arsabi_co/_layouts/15/Doc.aspx?sourcedoc=%7B955A41DF-D323-4FC3-80FD-0E78C10956C5%7D&file=TH%20Informes%20de%20visitas.xlsx&action=default&mobileredirect=true",
  "https://arsabi-my.sharepoint.com/personal/juan_quesada_arsabi_co/_layouts/15/Doc.aspx?sourcedoc=%7BC92F1A11-5BDD-4500-8719-E85C1C0717F5%7D&file=TD%20Inventario%20DB.xlsx&action=default&mobileredirect=true",
  "https://arsabi-my.sharepoint.com/personal/juan_quesada_arsabi_co/_layouts/15/Doc.aspx?sourcedoc=%7BDA499448-304D-40E4-BC6F-A2F74F01ECD5%7D&file=TD%20Busqueda%20de%20terceros.xlsx&action=default&mobileredirect=true",
];

export const juan_bd_download = [
  "https://arsabi-my.sharepoint.com/personal/juan_quesada_arsabi_co/_layouts/15/download.aspx?UniqueId=6b8c5b07-cebf-4abb-a9b6-ef6169840a10&Translate=false&tempauth=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBfZGlzcGxheW5hbWUiOiJHcmFwaCBFeHBsb3JlciIsImFwcGlkIjoiZGU4YmM4YjUtZDlmOS00OGIxLWE4YWQtYjc0OGRhNzI1MDY0IiwiYXVkIjoiMDAwMDAwMDMtMDAwMC0wZmYxLWNlMDAtMDAwMDAwMDAwMDAwL2Fyc2FiaS1teS5zaGFyZXBvaW50LmNvbUAzMDVmYzAyMi01MjE3LTQ5MWYtOTQyOS04MDNkZWUxNjFkZDEiLCJjYWNoZWtleSI6IjBoLmZ8bWVtYmVyc2hpcHwxMDAzMjAwMzZiODJkODhlQGxpdmUuY29tIiwiY2lkIjoiVUpaYkhvWEdaVWFFVmtnSTU4MEhhdz09IiwiZW5kcG9pbnR1cmwiOiIxK1RLV0lLRVhrQ2tZR3NOQno3aDJWbTMrYlIwdHRST2JmT1ZlcUJVdzhBPSIsImVuZHBvaW50dXJsTGVuZ3RoIjoiMTUyIiwiZXhwIjoiMTcxNzcyMjY0OSIsImZhbWlseV9uYW1lIjoiUXVlc2FkYSIsImdpdmVuX25hbWUiOiJKdWFuIiwiaXBhZGRyIjoiNDAuMTI2LjI0LjE1MiIsImlzbG9vcGJhY2siOiJUcnVlIiwiaXNzIjoiMDAwMDAwMDMtMDAwMC0wZmYxLWNlMDAtMDAwMDAwMDAwMDAwIiwibmJmIjoiMTcxNzcxOTA0OSIsInB1aWQiOiIxMDAzMjAwMzZCODJEODhFIiwic2NwIjoibXlmaWxlcy5yZWFkIGFsbGZpbGVzLnJlYWQgYWxsc2l0ZXMucmVhZCBhbGxzaXRlcy53cml0ZSBhbGxwcm9maWxlcy5yZWFkIiwic2lnbmluX3N0YXRlIjoiW1wia21zaVwiXSIsInNpdGVpZCI6Ik1URmxZak16TkdJdE9XVTRPQzAwTVRRMExXSTRNalF0TlROaE5tVXdNMlJpT0RsbSIsInRpZCI6IjMwNWZjMDIyLTUyMTctNDkxZi05NDI5LTgwM2RlZTE2MWRkMSIsInR0IjoiMiIsInVwbiI6Imp1YW4ucXVlc2FkYUBhcnNhYmkuY28iLCJ2ZXIiOiJoYXNoZWRwcm9vZnRva2VuIn0.G89Cwxe1XTECF-VlrLviAYBqkVmYTXXUxEo3X2dm_pI&ApiVersion=2.0",
  "https://arsabi-my.sharepoint.com/personal/juan_quesada_arsabi_co/_layouts/15/download.aspx?UniqueId=7f593dd4-5726-4858-a98b-50bec6dd7ad4&Translate=false&tempauth=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBfZGlzcGxheW5hbWUiOiJHcmFwaCBFeHBsb3JlciIsImFwcGlkIjoiZGU4YmM4YjUtZDlmOS00OGIxLWE4YWQtYjc0OGRhNzI1MDY0IiwiYXVkIjoiMDAwMDAwMDMtMDAwMC0wZmYxLWNlMDAtMDAwMDAwMDAwMDAwL2Fyc2FiaS1teS5zaGFyZXBvaW50LmNvbUAzMDVmYzAyMi01MjE3LTQ5MWYtOTQyOS04MDNkZWUxNjFkZDEiLCJjYWNoZWtleSI6IjBoLmZ8bWVtYmVyc2hpcHwxMDAzMjAwMzZiODJkODhlQGxpdmUuY29tIiwiY2lkIjoiQzJjSnN3R1h0RXVDdGl6ak5NZnIyQT09IiwiZW5kcG9pbnR1cmwiOiJTMkNxMHNJQkJ1YmhSd1piQmEzbHg1UVN5eVZVK1RrVDZxSkJuV21CVWxNPSIsImVuZHBvaW50dXJsTGVuZ3RoIjoiMTUyIiwiZXhwIjoiMTcxNzcyMjg4OSIsImZhbWlseV9uYW1lIjoiUXVlc2FkYSIsImdpdmVuX25hbWUiOiJKdWFuIiwiaXBhZGRyIjoiNDAuMTI2LjI0LjE1MiIsImlzbG9vcGJhY2siOiJUcnVlIiwiaXNzIjoiMDAwMDAwMDMtMDAwMC0wZmYxLWNlMDAtMDAwMDAwMDAwMDAwIiwibmJmIjoiMTcxNzcxOTI4OSIsInB1aWQiOiIxMDAzMjAwMzZCODJEODhFIiwic2NwIjoibXlmaWxlcy5yZWFkIGFsbGZpbGVzLnJlYWQgYWxsc2l0ZXMucmVhZCBhbGxzaXRlcy53cml0ZSBhbGxwcm9maWxlcy5yZWFkIiwic2lnbmluX3N0YXRlIjoiW1wia21zaVwiXSIsInNpdGVpZCI6Ik1URmxZak16TkdJdE9XVTRPQzAwTVRRMExXSTRNalF0TlROaE5tVXdNMlJpT0RsbSIsInRpZCI6IjMwNWZjMDIyLTUyMTctNDkxZi05NDI5LTgwM2RlZTE2MWRkMSIsInR0IjoiMiIsInVwbiI6Imp1YW4ucXVlc2FkYUBhcnNhYmkuY28iLCJ2ZXIiOiJoYXNoZWRwcm9vZnRva2VuIn0.7l2GbXA8iN8mnRwpNOLcnJRFkKJ4h0W7nxB8waVpgZk&ApiVersion=2.0",
  "https://arsabi-my.sharepoint.com/personal/juan_quesada_arsabi_co/_layouts/15/download.aspx?UniqueId=955a41df-d323-4fc3-80fd-0e78c10956c5&Translate=false&tempauth=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBfZGlzcGxheW5hbWUiOiJHcmFwaCBFeHBsb3JlciIsImFwcGlkIjoiZGU4YmM4YjUtZDlmOS00OGIxLWE4YWQtYjc0OGRhNzI1MDY0IiwiYXVkIjoiMDAwMDAwMDMtMDAwMC0wZmYxLWNlMDAtMDAwMDAwMDAwMDAwL2Fyc2FiaS1teS5zaGFyZXBvaW50LmNvbUAzMDVmYzAyMi01MjE3LTQ5MWYtOTQyOS04MDNkZWUxNjFkZDEiLCJjYWNoZWtleSI6IjBoLmZ8bWVtYmVyc2hpcHwxMDAzMjAwMzZiODJkODhlQGxpdmUuY29tIiwiY2lkIjoiQzJjSnN3R1h0RXVDdGl6ak5NZnIyQT09IiwiZW5kcG9pbnR1cmwiOiJ1SzhpSjBBZEJtcmVPaDZEQlgzeEl2K3haMmhsbGlHTVZSTHFjVHk4UFNJPSIsImVuZHBvaW50dXJsTGVuZ3RoIjoiMTUyIiwiZXhwIjoiMTcxNzcyMjg4OSIsImZhbWlseV9uYW1lIjoiUXVlc2FkYSIsImdpdmVuX25hbWUiOiJKdWFuIiwiaXBhZGRyIjoiNDAuMTI2LjI0LjE1MiIsImlzbG9vcGJhY2siOiJUcnVlIiwiaXNzIjoiMDAwMDAwMDMtMDAwMC0wZmYxLWNlMDAtMDAwMDAwMDAwMDAwIiwibmJmIjoiMTcxNzcxOTI4OSIsInB1aWQiOiIxMDAzMjAwMzZCODJEODhFIiwic2NwIjoibXlmaWxlcy5yZWFkIGFsbGZpbGVzLnJlYWQgYWxsc2l0ZXMucmVhZCBhbGxzaXRlcy53cml0ZSBhbGxwcm9maWxlcy5yZWFkIiwic2lnbmluX3N0YXRlIjoiW1wia21zaVwiXSIsInNpdGVpZCI6Ik1URmxZak16TkdJdE9XVTRPQzAwTVRRMExXSTRNalF0TlROaE5tVXdNMlJpT0RsbSIsInRpZCI6IjMwNWZjMDIyLTUyMTctNDkxZi05NDI5LTgwM2RlZTE2MWRkMSIsInR0IjoiMiIsInVwbiI6Imp1YW4ucXVlc2FkYUBhcnNhYmkuY28iLCJ2ZXIiOiJoYXNoZWRwcm9vZnRva2VuIn0.feNCLklYWd7tsXwZXKw__zJ_gj_zuXQZtIV89tGL27M&ApiVersion=2.0",
  "https://arsabi-my.sharepoint.com/personal/juan_quesada_arsabi_co/_layouts/15/download.aspx?UniqueId=c92f1a11-5bdd-4500-8719-e85c1c0717f5&Translate=false&tempauth=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBfZGlzcGxheW5hbWUiOiJHcmFwaCBFeHBsb3JlciIsImFwcGlkIjoiZGU4YmM4YjUtZDlmOS00OGIxLWE4YWQtYjc0OGRhNzI1MDY0IiwiYXVkIjoiMDAwMDAwMDMtMDAwMC0wZmYxLWNlMDAtMDAwMDAwMDAwMDAwL2Fyc2FiaS1teS5zaGFyZXBvaW50LmNvbUAzMDVmYzAyMi01MjE3LTQ5MWYtOTQyOS04MDNkZWUxNjFkZDEiLCJjYWNoZWtleSI6IjBoLmZ8bWVtYmVyc2hpcHwxMDAzMjAwMzZiODJkODhlQGxpdmUuY29tIiwiY2lkIjoiQzJjSnN3R1h0RXVDdGl6ak5NZnIyQT09IiwiZW5kcG9pbnR1cmwiOiJyS3N0dCt1cVJYd2Q3ZEdhTisvcWNtQi9paVpoelBqMHhHeElqaUZPNU9nPSIsImVuZHBvaW50dXJsTGVuZ3RoIjoiMTUyIiwiZXhwIjoiMTcxNzcyMjg4OSIsImZhbWlseV9uYW1lIjoiUXVlc2FkYSIsImdpdmVuX25hbWUiOiJKdWFuIiwiaXBhZGRyIjoiNDAuMTI2LjI0LjE1MiIsImlzbG9vcGJhY2siOiJUcnVlIiwiaXNzIjoiMDAwMDAwMDMtMDAwMC0wZmYxLWNlMDAtMDAwMDAwMDAwMDAwIiwibmJmIjoiMTcxNzcxOTI4OSIsInB1aWQiOiIxMDAzMjAwMzZCODJEODhFIiwic2NwIjoibXlmaWxlcy5yZWFkIGFsbGZpbGVzLnJlYWQgYWxsc2l0ZXMucmVhZCBhbGxzaXRlcy53cml0ZSBhbGxwcm9maWxlcy5yZWFkIiwic2lnbmluX3N0YXRlIjoiW1wia21zaVwiXSIsInNpdGVpZCI6Ik1URmxZak16TkdJdE9XVTRPQzAwTVRRMExXSTRNalF0TlROaE5tVXdNMlJpT0RsbSIsInRpZCI6IjMwNWZjMDIyLTUyMTctNDkxZi05NDI5LTgwM2RlZTE2MWRkMSIsInR0IjoiMiIsInVwbiI6Imp1YW4ucXVlc2FkYUBhcnNhYmkuY28iLCJ2ZXIiOiJoYXNoZWRwcm9vZnRva2VuIn0.WZ_MU9OQsiWXSuPw4RfaDaw-RUZQVl_vIKbnaznXuQ0&ApiVersion=2.0",
  "https://arsabi-my.sharepoint.com/personal/juan_quesada_arsabi_co/_layouts/15/download.aspx?UniqueId=da499448-304d-40e4-bc6f-a2f74f01ecd5&Translate=false&tempauth=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBfZGlzcGxheW5hbWUiOiJHcmFwaCBFeHBsb3JlciIsImFwcGlkIjoiZGU4YmM4YjUtZDlmOS00OGIxLWE4YWQtYjc0OGRhNzI1MDY0IiwiYXVkIjoiMDAwMDAwMDMtMDAwMC0wZmYxLWNlMDAtMDAwMDAwMDAwMDAwL2Fyc2FiaS1teS5zaGFyZXBvaW50LmNvbUAzMDVmYzAyMi01MjE3LTQ5MWYtOTQyOS04MDNkZWUxNjFkZDEiLCJjYWNoZWtleSI6IjBoLmZ8bWVtYmVyc2hpcHwxMDAzMjAwMzZiODJkODhlQGxpdmUuY29tIiwiY2lkIjoiQzJjSnN3R1h0RXVDdGl6ak5NZnIyQT09IiwiZW5kcG9pbnR1cmwiOiJHOFp5TkdXRC95ek5wMWRnQXFPcGhoOVkveE1SMzlSOVpiNUE0WjY5NWg0PSIsImVuZHBvaW50dXJsTGVuZ3RoIjoiMTUyIiwiZXhwIjoiMTcxNzcyMjg4OSIsImZhbWlseV9uYW1lIjoiUXVlc2FkYSIsImdpdmVuX25hbWUiOiJKdWFuIiwiaXBhZGRyIjoiNDAuMTI2LjI0LjE1MiIsImlzbG9vcGJhY2siOiJUcnVlIiwiaXNzIjoiMDAwMDAwMDMtMDAwMC0wZmYxLWNlMDAtMDAwMDAwMDAwMDAwIiwibmJmIjoiMTcxNzcxOTI4OSIsInB1aWQiOiIxMDAzMjAwMzZCODJEODhFIiwic2NwIjoibXlmaWxlcy5yZWFkIGFsbGZpbGVzLnJlYWQgYWxsc2l0ZXMucmVhZCBhbGxzaXRlcy53cml0ZSBhbGxwcm9maWxlcy5yZWFkIiwic2lnbmluX3N0YXRlIjoiW1wia21zaVwiXSIsInNpdGVpZCI6Ik1URmxZak16TkdJdE9XVTRPQzAwTVRRMExXSTRNalF0TlROaE5tVXdNMlJpT0RsbSIsInRpZCI6IjMwNWZjMDIyLTUyMTctNDkxZi05NDI5LTgwM2RlZTE2MWRkMSIsInR0IjoiMiIsInVwbiI6Imp1YW4ucXVlc2FkYUBhcnNhYmkuY28iLCJ2ZXIiOiJoYXNoZWRwcm9vZnRva2VuIn0.x0Ru7mY8EW8RmQJW4SL3C-1vcFCzTGdNloU_aYzEEfw&ApiVersion=2.0",
];

export const juan_projects = ["1.Lanuza", "2.BI_Visitas"];
export const laura_projects = ["1.Informe_Gerencial"];
export const jeyson_projects = ["1.Informe_General_Arsa", "2.Informe_ventas"];

export const jeyson_proyecto_1_doc = ["Plantilla.png"];
export const jeyson_proyecto_1_doc_view = [
  "https://arsabi-my.sharepoint.com/personal/jeyson_arsabi_co/Documents/Proyectos/1.Informe_General_Arsa/DOC/Plantilla.png",
];
export const jeyson_proyecto_1_doc_download = [
  "https://arsabi-my.sharepoint.com/personal/jeyson_arsabi_co/_layouts/15/download.aspx?UniqueId=3dafd512-ca9e-41d2-99d0-735419100078&Translate=false&tempauth=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBfZGlzcGxheW5hbWUiOiJHcmFwaCBFeHBsb3JlciIsImFwcGlkIjoiZGU4YmM4YjUtZDlmOS00OGIxLWE4YWQtYjc0OGRhNzI1MDY0IiwiYXVkIjoiMDAwMDAwMDMtMDAwMC0wZmYxLWNlMDAtMDAwMDAwMDAwMDAwL2Fyc2FiaS1teS5zaGFyZXBvaW50LmNvbUAzMDVmYzAyMi01MjE3LTQ5MWYtOTQyOS04MDNkZWUxNjFkZDEiLCJjYWNoZWtleSI6IjBoLmZ8bWVtYmVyc2hpcHwxMDAzMjAwMTAzYWU1MzRkQGxpdmUuY29tIiwiY2lkIjoiVFdzMU5qSUo5Q0JJQXljRTFKbXE1dz09IiwiZW5kcG9pbnR1cmwiOiI5OTBBNHZrcnRicVltSW5UNlhtT0QvSGdkajRCYTVjMTNPcTVEVmozSGljPSIsImVuZHBvaW50dXJsTGVuZ3RoIjoiMTQ2IiwiZXhwIjoiMTcxNzcxOTY3MCIsImZhbWlseV9uYW1lIjoiQXJzYWJpIiwiZ2l2ZW5fbmFtZSI6IkpleXNvbiIsImlwYWRkciI6IjIwLjE5MC4xNTIuODgiLCJpc2xvb3BiYWNrIjoiVHJ1ZSIsImlzcyI6IjAwMDAwMDAzLTAwMDAtMGZmMS1jZTAwLTAwMDAwMDAwMDAwMCIsIm5iZiI6IjE3MTc3MTYwNzAiLCJwdWlkIjoiMTAwMzIwMDEwM0FFNTM0RCIsInNjcCI6Im15ZmlsZXMucmVhZCBhbGxmaWxlcy5yZWFkIG15ZmlsZXMud3JpdGUgYWxsZmlsZXMud3JpdGUgbXlhcHBmb2xkZXIud3JpdGUgYWxsc2l0ZXMucmVhZCBhbGxzaXRlcy53cml0ZSBhbGxwcm9maWxlcy5yZWFkIiwic2l0ZWlkIjoiWlRkbU5EZG1OR1F0TURZeE5pMDBZV1ZpTFRsaE9UQXRPV0V5TVRZMVpXTmtNakZsIiwidGlkIjoiMzA1ZmMwMjItNTIxNy00OTFmLTk0MjktODAzZGVlMTYxZGQxIiwidHQiOiIyIiwidXBuIjoiamV5c29uQGFyc2FiaS5jbyIsInZlciI6Imhhc2hlZHByb29mdG9rZW4ifQ.6a5HVh9SPC4Nukhl_pHCO42mry4jguFtetXcR3a92u8&ApiVersion=2.0",
];
export const jeyson_proyecto_1_report = ["Inf. Ventas BI.pbix"];
export const jeyson_proyecto_1_bd = [
  "2018.xlsx",
  "2016.xlsx",
  "2017.xlsx",
  "BD_Clientes.xlsx",
  "BD_Canal.xlsx",
  "GeoportaldelDANE.xlsx",
  "BD_Productos.xlsx",
  "BD_Vendedor.xlsx",
];
export const jeyson_proyecto_1_bd_view = [
  "https://arsabi-my.sharepoint.com/personal/jeyson_arsabi_co/_layouts/15/Doc.aspx?sourcedoc=%7BF7961722-CC67-47A2-BEA1-20A5F8866362%7D&file=2016.xlsx&action=default&mobileredirect=true",
  "https://arsabi-my.sharepoint.com/personal/jeyson_arsabi_co/_layouts/15/Doc.aspx?sourcedoc=%7BC66316E7-A3BE-45C5-86D8-E60D11924710%7D&file=2017.xlsx&action=default&mobileredirect=true",
  "https://arsabi-my.sharepoint.com/personal/jeyson_arsabi_co/_layouts/15/Doc.aspx?sourcedoc=%7B9DF37A47-A57E-4FCF-BCDE-74C5EBD9D5DC%7D&file=2018.xlsx&action=default&mobileredirect=true",
  "https://arsabi-my.sharepoint.com/personal/jeyson_arsabi_co/_layouts/15/Doc.aspx?sourcedoc=%7B62F4BD31-5553-4432-88C8-5039436C37DE%7D&file=BD_Canal.xlsx&action=default&mobileredirect=true",
  "https://arsabi-my.sharepoint.com/personal/jeyson_arsabi_co/_layouts/15/Doc.aspx?sourcedoc=%7B43AFC8E3-E8D0-434B-96A4-DF7AAADAD54B%7D&file=BD_Clientes.xlsx&action=default&mobileredirect=true",
  "https://arsabi-my.sharepoint.com/personal/jeyson_arsabi_co/_layouts/15/Doc.aspx?sourcedoc=%7BDA8D1CA9-CF8C-4D27-BABC-CE8F98CA5D8A%7D&file=BD_Productos.xlsx&action=default&mobileredirect=true",
  "https://arsabi-my.sharepoint.com/personal/jeyson_arsabi_co/_layouts/15/Doc.aspx?sourcedoc=%7BD112F2ED-8164-4BA0-AA7C-7270FF71ADEA%7D&file=BD_Vendedor.xlsx&action=default&mobileredirect=true",
  "https://arsabi-my.sharepoint.com/personal/jeyson_arsabi_co/_layouts/15/Doc.aspx?sourcedoc=%7B8094733A-843F-4F6A-9915-0F731E9B509F%7D&file=GeoportaldelDANE.xlsx&action=default&mobileredirect=true",
];
export const jeyson_projects_2_bd_view = [
  "https://arsabi-my.sharepoint.com/personal/jeyson_arsabi_co/_layouts/15/Doc.aspx?sourcedoc=%7B5806BFFA-6014-43F5-B99B-AFAA131EA59C%7D&file=TD%20Asesores.xlsx&action=default&mobileredirect=true",
];
export const jeyson_projects_2_doc_view = [
  "https://arsabi-my.sharepoint.com/personal/jeyson_arsabi_co/Documents/Proyectos/2.Informe_Visitas/DOC/Link%20Informe%20PBI.txt",
  "https://arsabi-my.sharepoint.com/personal/jeyson_arsabi_co/Documents/Proyectos/2.Informe_Visitas/DOC/Fondo%201280x1500%20Arsa%20BI.png",
  "https://arsabi-my.sharepoint.com/personal/jeyson_arsabi_co/_layouts/15/Doc.aspx?sourcedoc=%7B3823EA45-47BB-468D-B4DA-21F033E2DC53%7D&file=5-Objetivos%20Semanales%20Mayo.xlsx&action=default&mobileredirect=true",
  "https://arsabi-my.sharepoint.com/personal/jeyson_arsabi_co/_layouts/15/Doc.aspx?sourcedoc=%7BF923DA32-EA37-4D19-860D-188AA4461502%7D&file=4-Objetivos%20Semanales%20Abril.xlsx&action=default&mobileredirect=true",
];
export const jeyson_proyecto_1_bd_download = [
  "https://arsabi-my.sharepoint.com/personal/jeyson_arsabi_co/_layouts/15/download.aspx?UniqueId=f7961722-cc67-47a2-bea1-20a5f8866362&Translate=false&tempauth=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBfZGlzcGxheW5hbWUiOiJHcmFwaCBFeHBsb3JlciIsImFwcGlkIjoiZGU4YmM4YjUtZDlmOS00OGIxLWE4YWQtYjc0OGRhNzI1MDY0IiwiYXVkIjoiMDAwMDAwMDMtMDAwMC0wZmYxLWNlMDAtMDAwMDAwMDAwMDAwL2Fyc2FiaS1teS5zaGFyZXBvaW50LmNvbUAzMDVmYzAyMi01MjE3LTQ5MWYtOTQyOS04MDNkZWUxNjFkZDEiLCJjYWNoZWtleSI6IjBoLmZ8bWVtYmVyc2hpcHwxMDAzMjAwMTAzYWU1MzRkQGxpdmUuY29tIiwiY2lkIjoiajRJZnVJUjNsbHdYY29WNlVYQ1VhQT09IiwiZW5kcG9pbnR1cmwiOiJldUJhcTZrR0E1SXFsNmU4KzY3MlJTYWlQUThjeXROam9VL3Z4Y2JEbGFvPSIsImVuZHBvaW50dXJsTGVuZ3RoIjoiMTQ2IiwiZXhwIjoiMTcxNzcyMDE0OCIsImZhbWlseV9uYW1lIjoiQXJzYWJpIiwiZ2l2ZW5fbmFtZSI6IkpleXNvbiIsImlwYWRkciI6IjIwLjE5MC4xNTIuMTUyIiwiaXNsb29wYmFjayI6IlRydWUiLCJpc3MiOiIwMDAwMDAwMy0wMDAwLTBmZjEtY2UwMC0wMDAwMDAwMDAwMDAiLCJuYmYiOiIxNzE3NzE2NTQ4IiwicHVpZCI6IjEwMDMyMDAxMDNBRTUzNEQiLCJzY3AiOiJteWZpbGVzLnJlYWQgYWxsZmlsZXMucmVhZCBteWZpbGVzLndyaXRlIGFsbGZpbGVzLndyaXRlIG15YXBwZm9sZGVyLndyaXRlIGFsbHNpdGVzLnJlYWQgYWxsc2l0ZXMud3JpdGUgYWxscHJvZmlsZXMucmVhZCIsInNpdGVpZCI6IlpUZG1ORGRtTkdRdE1EWXhOaTAwWVdWaUxUbGhPVEF0T1dFeU1UWTFaV05rTWpGbCIsInRpZCI6IjMwNWZjMDIyLTUyMTctNDkxZi05NDI5LTgwM2RlZTE2MWRkMSIsInR0IjoiMiIsInVwbiI6ImpleXNvbkBhcnNhYmkuY28iLCJ2ZXIiOiJoYXNoZWRwcm9vZnRva2VuIn0.7omLUBGqh-Et97vQ3sEL3uHOBBbNmZjrrSlMVgO7I-0&ApiVersion=2.0",
  "https://arsabi-my.sharepoint.com/personal/jeyson_arsabi_co/_layouts/15/download.aspx?UniqueId=c66316e7-a3be-45c5-86d8-e60d11924710&Translate=false&tempauth=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBfZGlzcGxheW5hbWUiOiJHcmFwaCBFeHBsb3JlciIsImFwcGlkIjoiZGU4YmM4YjUtZDlmOS00OGIxLWE4YWQtYjc0OGRhNzI1MDY0IiwiYXVkIjoiMDAwMDAwMDMtMDAwMC0wZmYxLWNlMDAtMDAwMDAwMDAwMDAwL2Fyc2FiaS1teS5zaGFyZXBvaW50LmNvbUAzMDVmYzAyMi01MjE3LTQ5MWYtOTQyOS04MDNkZWUxNjFkZDEiLCJjYWNoZWtleSI6IjBoLmZ8bWVtYmVyc2hpcHwxMDAzMjAwMTAzYWU1MzRkQGxpdmUuY29tIiwiY2lkIjoiajRJZnVJUjNsbHdYY29WNlVYQ1VhQT09IiwiZW5kcG9pbnR1cmwiOiJKSjFGSXU3QkNIQTdjTUp0R2tSSk9wd20vaThDUjBJdThvTlV5NUg3NDhRPSIsImVuZHBvaW50dXJsTGVuZ3RoIjoiMTQ2IiwiZXhwIjoiMTcxNzcyMDE0OCIsImZhbWlseV9uYW1lIjoiQXJzYWJpIiwiZ2l2ZW5fbmFtZSI6IkpleXNvbiIsImlwYWRkciI6IjIwLjE5MC4xNTIuMTUyIiwiaXNsb29wYmFjayI6IlRydWUiLCJpc3MiOiIwMDAwMDAwMy0wMDAwLTBmZjEtY2UwMC0wMDAwMDAwMDAwMDAiLCJuYmYiOiIxNzE3NzE2NTQ4IiwicHVpZCI6IjEwMDMyMDAxMDNBRTUzNEQiLCJzY3AiOiJteWZpbGVzLnJlYWQgYWxsZmlsZXMucmVhZCBteWZpbGVzLndyaXRlIGFsbGZpbGVzLndyaXRlIG15YXBwZm9sZGVyLndyaXRlIGFsbHNpdGVzLnJlYWQgYWxsc2l0ZXMud3JpdGUgYWxscHJvZmlsZXMucmVhZCIsInNpdGVpZCI6IlpUZG1ORGRtTkdRdE1EWXhOaTAwWVdWaUxUbGhPVEF0T1dFeU1UWTFaV05rTWpGbCIsInRpZCI6IjMwNWZjMDIyLTUyMTctNDkxZi05NDI5LTgwM2RlZTE2MWRkMSIsInR0IjoiMiIsInVwbiI6ImpleXNvbkBhcnNhYmkuY28iLCJ2ZXIiOiJoYXNoZWRwcm9vZnRva2VuIn0.9aYa56BJHcaSOsqs0pIrn8aJaLKtUjATvzoxfIKnEZE&ApiVersion=2.0",
  "https://arsabi-my.sharepoint.com/personal/jeyson_arsabi_co/_layouts/15/download.aspx?UniqueId=9df37a47-a57e-4fcf-bcde-74c5ebd9d5dc&Translate=false&tempauth=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBfZGlzcGxheW5hbWUiOiJHcmFwaCBFeHBsb3JlciIsImFwcGlkIjoiZGU4YmM4YjUtZDlmOS00OGIxLWE4YWQtYjc0OGRhNzI1MDY0IiwiYXVkIjoiMDAwMDAwMDMtMDAwMC0wZmYxLWNlMDAtMDAwMDAwMDAwMDAwL2Fyc2FiaS1teS5zaGFyZXBvaW50LmNvbUAzMDVmYzAyMi01MjE3LTQ5MWYtOTQyOS04MDNkZWUxNjFkZDEiLCJjYWNoZWtleSI6IjBoLmZ8bWVtYmVyc2hpcHwxMDAzMjAwMTAzYWU1MzRkQGxpdmUuY29tIiwiY2lkIjoiajRJZnVJUjNsbHdYY29WNlVYQ1VhQT09IiwiZW5kcG9pbnR1cmwiOiJSN0tzQ2h4Zk90d0xxbFRkRXlBanFpUFVnTHpNZDBtVjNQaVJrU3RsY0w4PSIsImVuZHBvaW50dXJsTGVuZ3RoIjoiMTQ2IiwiZXhwIjoiMTcxNzcyMDE0OCIsImZhbWlseV9uYW1lIjoiQXJzYWJpIiwiZ2l2ZW5fbmFtZSI6IkpleXNvbiIsImlwYWRkciI6IjIwLjE5MC4xNTIuMTUyIiwiaXNsb29wYmFjayI6IlRydWUiLCJpc3MiOiIwMDAwMDAwMy0wMDAwLTBmZjEtY2UwMC0wMDAwMDAwMDAwMDAiLCJuYmYiOiIxNzE3NzE2NTQ4IiwicHVpZCI6IjEwMDMyMDAxMDNBRTUzNEQiLCJzY3AiOiJteWZpbGVzLnJlYWQgYWxsZmlsZXMucmVhZCBteWZpbGVzLndyaXRlIGFsbGZpbGVzLndyaXRlIG15YXBwZm9sZGVyLndyaXRlIGFsbHNpdGVzLnJlYWQgYWxsc2l0ZXMud3JpdGUgYWxscHJvZmlsZXMucmVhZCIsInNpdGVpZCI6IlpUZG1ORGRtTkdRdE1EWXhOaTAwWVdWaUxUbGhPVEF0T1dFeU1UWTFaV05rTWpGbCIsInRpZCI6IjMwNWZjMDIyLTUyMTctNDkxZi05NDI5LTgwM2RlZTE2MWRkMSIsInR0IjoiMiIsInVwbiI6ImpleXNvbkBhcnNhYmkuY28iLCJ2ZXIiOiJoYXNoZWRwcm9vZnRva2VuIn0.-n4a7cxCvDDOyfCoH1nMRcQ8EH3TG9-n9gGmPxVDwh8&ApiVersion=2.0",
  "https://arsabi-my.sharepoint.com/personal/jeyson_arsabi_co/_layouts/15/download.aspx?UniqueId=62f4bd31-5553-4432-88c8-5039436c37de&Translate=false&tempauth=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBfZGlzcGxheW5hbWUiOiJHcmFwaCBFeHBsb3JlciIsImFwcGlkIjoiZGU4YmM4YjUtZDlmOS00OGIxLWE4YWQtYjc0OGRhNzI1MDY0IiwiYXVkIjoiMDAwMDAwMDMtMDAwMC0wZmYxLWNlMDAtMDAwMDAwMDAwMDAwL2Fyc2FiaS1teS5zaGFyZXBvaW50LmNvbUAzMDVmYzAyMi01MjE3LTQ5MWYtOTQyOS04MDNkZWUxNjFkZDEiLCJjYWNoZWtleSI6IjBoLmZ8bWVtYmVyc2hpcHwxMDAzMjAwMTAzYWU1MzRkQGxpdmUuY29tIiwiY2lkIjoiajRJZnVJUjNsbHdYY29WNlVYQ1VhQT09IiwiZW5kcG9pbnR1cmwiOiJmVkVkN3Jvd1dsWGJMeC9BOXNPUlNVVnhsQnFUL1RGYlQwekQ0cFJJbkVnPSIsImVuZHBvaW50dXJsTGVuZ3RoIjoiMTQ2IiwiZXhwIjoiMTcxNzcyMDE0OCIsImZhbWlseV9uYW1lIjoiQXJzYWJpIiwiZ2l2ZW5fbmFtZSI6IkpleXNvbiIsImlwYWRkciI6IjIwLjE5MC4xNTIuMTUyIiwiaXNsb29wYmFjayI6IlRydWUiLCJpc3MiOiIwMDAwMDAwMy0wMDAwLTBmZjEtY2UwMC0wMDAwMDAwMDAwMDAiLCJuYmYiOiIxNzE3NzE2NTQ4IiwicHVpZCI6IjEwMDMyMDAxMDNBRTUzNEQiLCJzY3AiOiJteWZpbGVzLnJlYWQgYWxsZmlsZXMucmVhZCBteWZpbGVzLndyaXRlIGFsbGZpbGVzLndyaXRlIG15YXBwZm9sZGVyLndyaXRlIGFsbHNpdGVzLnJlYWQgYWxsc2l0ZXMud3JpdGUgYWxscHJvZmlsZXMucmVhZCIsInNpdGVpZCI6IlpUZG1ORGRtTkdRdE1EWXhOaTAwWVdWaUxUbGhPVEF0T1dFeU1UWTFaV05rTWpGbCIsInRpZCI6IjMwNWZjMDIyLTUyMTctNDkxZi05NDI5LTgwM2RlZTE2MWRkMSIsInR0IjoiMiIsInVwbiI6ImpleXNvbkBhcnNhYmkuY28iLCJ2ZXIiOiJoYXNoZWRwcm9vZnRva2VuIn0.jq8c0Rza2AGbHrFOrpxuBvGYMmbPbgsncH-ZqfRhqqE&ApiVersion=2.0",
  "https://arsabi-my.sharepoint.com/personal/jeyson_arsabi_co/_layouts/15/download.aspx?UniqueId=43afc8e3-e8d0-434b-96a4-df7aaadad54b&Translate=false&tempauth=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBfZGlzcGxheW5hbWUiOiJHcmFwaCBFeHBsb3JlciIsImFwcGlkIjoiZGU4YmM4YjUtZDlmOS00OGIxLWE4YWQtYjc0OGRhNzI1MDY0IiwiYXVkIjoiMDAwMDAwMDMtMDAwMC0wZmYxLWNlMDAtMDAwMDAwMDAwMDAwL2Fyc2FiaS1teS5zaGFyZXBvaW50LmNvbUAzMDVmYzAyMi01MjE3LTQ5MWYtOTQyOS04MDNkZWUxNjFkZDEiLCJjYWNoZWtleSI6IjBoLmZ8bWVtYmVyc2hpcHwxMDAzMjAwMTAzYWU1MzRkQGxpdmUuY29tIiwiY2lkIjoiajRJZnVJUjNsbHdYY29WNlVYQ1VhQT09IiwiZW5kcG9pbnR1cmwiOiJYdUthNXJRM3FxUi8vMm1MVGxLSWlPVnpmS3A5RWJFcnFaQ2YwdnhyNzNzPSIsImVuZHBvaW50dXJsTGVuZ3RoIjoiMTQ2IiwiZXhwIjoiMTcxNzcyMDE0OCIsImZhbWlseV9uYW1lIjoiQXJzYWJpIiwiZ2l2ZW5fbmFtZSI6IkpleXNvbiIsImlwYWRkciI6IjIwLjE5MC4xNTIuMTUyIiwiaXNsb29wYmFjayI6IlRydWUiLCJpc3MiOiIwMDAwMDAwMy0wMDAwLTBmZjEtY2UwMC0wMDAwMDAwMDAwMDAiLCJuYmYiOiIxNzE3NzE2NTQ4IiwicHVpZCI6IjEwMDMyMDAxMDNBRTUzNEQiLCJzY3AiOiJteWZpbGVzLnJlYWQgYWxsZmlsZXMucmVhZCBteWZpbGVzLndyaXRlIGFsbGZpbGVzLndyaXRlIG15YXBwZm9sZGVyLndyaXRlIGFsbHNpdGVzLnJlYWQgYWxsc2l0ZXMud3JpdGUgYWxscHJvZmlsZXMucmVhZCIsInNpdGVpZCI6IlpUZG1ORGRtTkdRdE1EWXhOaTAwWVdWaUxUbGhPVEF0T1dFeU1UWTFaV05rTWpGbCIsInRpZCI6IjMwNWZjMDIyLTUyMTctNDkxZi05NDI5LTgwM2RlZTE2MWRkMSIsInR0IjoiMiIsInVwbiI6ImpleXNvbkBhcnNhYmkuY28iLCJ2ZXIiOiJoYXNoZWRwcm9vZnRva2VuIn0.DlgEfKssX8vnzo6xfLA6yzC7ZQDBZGbXFxHkHJILisw&ApiVersion=2.0",
  "https://arsabi-my.sharepoint.com/personal/jeyson_arsabi_co/_layouts/15/download.aspx?UniqueId=da8d1ca9-cf8c-4d27-babc-ce8f98ca5d8a&Translate=false&tempauth=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBfZGlzcGxheW5hbWUiOiJHcmFwaCBFeHBsb3JlciIsImFwcGlkIjoiZGU4YmM4YjUtZDlmOS00OGIxLWE4YWQtYjc0OGRhNzI1MDY0IiwiYXVkIjoiMDAwMDAwMDMtMDAwMC0wZmYxLWNlMDAtMDAwMDAwMDAwMDAwL2Fyc2FiaS1teS5zaGFyZXBvaW50LmNvbUAzMDVmYzAyMi01MjE3LTQ5MWYtOTQyOS04MDNkZWUxNjFkZDEiLCJjYWNoZWtleSI6IjBoLmZ8bWVtYmVyc2hpcHwxMDAzMjAwMTAzYWU1MzRkQGxpdmUuY29tIiwiY2lkIjoiajRJZnVJUjNsbHdYY29WNlVYQ1VhQT09IiwiZW5kcG9pbnR1cmwiOiJUZTAvU3RaUHB3QldFQzBQc2pUWEtsRU55ZFY5VHhBcVlNcnh4cFhyRzkwPSIsImVuZHBvaW50dXJsTGVuZ3RoIjoiMTQ2IiwiZXhwIjoiMTcxNzcyMDE0OCIsImZhbWlseV9uYW1lIjoiQXJzYWJpIiwiZ2l2ZW5fbmFtZSI6IkpleXNvbiIsImlwYWRkciI6IjIwLjE5MC4xNTIuMTUyIiwiaXNsb29wYmFjayI6IlRydWUiLCJpc3MiOiIwMDAwMDAwMy0wMDAwLTBmZjEtY2UwMC0wMDAwMDAwMDAwMDAiLCJuYmYiOiIxNzE3NzE2NTQ4IiwicHVpZCI6IjEwMDMyMDAxMDNBRTUzNEQiLCJzY3AiOiJteWZpbGVzLnJlYWQgYWxsZmlsZXMucmVhZCBteWZpbGVzLndyaXRlIGFsbGZpbGVzLndyaXRlIG15YXBwZm9sZGVyLndyaXRlIGFsbHNpdGVzLnJlYWQgYWxsc2l0ZXMud3JpdGUgYWxscHJvZmlsZXMucmVhZCIsInNpdGVpZCI6IlpUZG1ORGRtTkdRdE1EWXhOaTAwWVdWaUxUbGhPVEF0T1dFeU1UWTFaV05rTWpGbCIsInRpZCI6IjMwNWZjMDIyLTUyMTctNDkxZi05NDI5LTgwM2RlZTE2MWRkMSIsInR0IjoiMiIsInVwbiI6ImpleXNvbkBhcnNhYmkuY28iLCJ2ZXIiOiJoYXNoZWRwcm9vZnRva2VuIn0.8HvbmS4ndBcu0qEWLDByFidI2b_U_zSOpaP78yisUhU&ApiVersion=2.0",
  "https://arsabi-my.sharepoint.com/personal/jeyson_arsabi_co/_layouts/15/download.aspx?UniqueId=d112f2ed-8164-4ba0-aa7c-7270ff71adea&Translate=false&tempauth=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBfZGlzcGxheW5hbWUiOiJHcmFwaCBFeHBsb3JlciIsImFwcGlkIjoiZGU4YmM4YjUtZDlmOS00OGIxLWE4YWQtYjc0OGRhNzI1MDY0IiwiYXVkIjoiMDAwMDAwMDMtMDAwMC0wZmYxLWNlMDAtMDAwMDAwMDAwMDAwL2Fyc2FiaS1teS5zaGFyZXBvaW50LmNvbUAzMDVmYzAyMi01MjE3LTQ5MWYtOTQyOS04MDNkZWUxNjFkZDEiLCJjYWNoZWtleSI6IjBoLmZ8bWVtYmVyc2hpcHwxMDAzMjAwMTAzYWU1MzRkQGxpdmUuY29tIiwiY2lkIjoiajRJZnVJUjNsbHdYY29WNlVYQ1VhQT09IiwiZW5kcG9pbnR1cmwiOiJYbGlBWlpXdFEwQjRtd0JUcmY5ME9qK0xSbGZLemhZYUdEd21sTHZzdDJjPSIsImVuZHBvaW50dXJsTGVuZ3RoIjoiMTQ2IiwiZXhwIjoiMTcxNzcyMDE0OCIsImZhbWlseV9uYW1lIjoiQXJzYWJpIiwiZ2l2ZW5fbmFtZSI6IkpleXNvbiIsImlwYWRkciI6IjIwLjE5MC4xNTIuMTUyIiwiaXNsb29wYmFjayI6IlRydWUiLCJpc3MiOiIwMDAwMDAwMy0wMDAwLTBmZjEtY2UwMC0wMDAwMDAwMDAwMDAiLCJuYmYiOiIxNzE3NzE2NTQ4IiwicHVpZCI6IjEwMDMyMDAxMDNBRTUzNEQiLCJzY3AiOiJteWZpbGVzLnJlYWQgYWxsZmlsZXMucmVhZCBteWZpbGVzLndyaXRlIGFsbGZpbGVzLndyaXRlIG15YXBwZm9sZGVyLndyaXRlIGFsbHNpdGVzLnJlYWQgYWxsc2l0ZXMud3JpdGUgYWxscHJvZmlsZXMucmVhZCIsInNpdGVpZCI6IlpUZG1ORGRtTkdRdE1EWXhOaTAwWVdWaUxUbGhPVEF0T1dFeU1UWTFaV05rTWpGbCIsInRpZCI6IjMwNWZjMDIyLTUyMTctNDkxZi05NDI5LTgwM2RlZTE2MWRkMSIsInR0IjoiMiIsInVwbiI6ImpleXNvbkBhcnNhYmkuY28iLCJ2ZXIiOiJoYXNoZWRwcm9vZnRva2VuIn0.gVgNy0QiOmOr9sOyxgwoKhGFE6bWKmRaCeOkbF42Kkg&ApiVersion=2.0",
  "https://arsabi-my.sharepoint.com/personal/jeyson_arsabi_co/_layouts/15/download.aspx?UniqueId=8094733a-843f-4f6a-9915-0f731e9b509f&Translate=false&tempauth=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBfZGlzcGxheW5hbWUiOiJHcmFwaCBFeHBsb3JlciIsImFwcGlkIjoiZGU4YmM4YjUtZDlmOS00OGIxLWE4YWQtYjc0OGRhNzI1MDY0IiwiYXVkIjoiMDAwMDAwMDMtMDAwMC0wZmYxLWNlMDAtMDAwMDAwMDAwMDAwL2Fyc2FiaS1teS5zaGFyZXBvaW50LmNvbUAzMDVmYzAyMi01MjE3LTQ5MWYtOTQyOS04MDNkZWUxNjFkZDEiLCJjYWNoZWtleSI6IjBoLmZ8bWVtYmVyc2hpcHwxMDAzMjAwMTAzYWU1MzRkQGxpdmUuY29tIiwiY2lkIjoiajRJZnVJUjNsbHdYY29WNlVYQ1VhQT09IiwiZW5kcG9pbnR1cmwiOiI1dERSYnJVQ1dkc3ZGenl0MkdQcXVDTDZsOVM1OEVMZGtTTDRCUVRjQzRNPSIsImVuZHBvaW50dXJsTGVuZ3RoIjoiMTQ2IiwiZXhwIjoiMTcxNzcyMDE0OCIsImZhbWlseV9uYW1lIjoiQXJzYWJpIiwiZ2l2ZW5fbmFtZSI6IkpleXNvbiIsImlwYWRkciI6IjIwLjE5MC4xNTIuMTUyIiwiaXNsb29wYmFjayI6IlRydWUiLCJpc3MiOiIwMDAwMDAwMy0wMDAwLTBmZjEtY2UwMC0wMDAwMDAwMDAwMDAiLCJuYmYiOiIxNzE3NzE2NTQ4IiwicHVpZCI6IjEwMDMyMDAxMDNBRTUzNEQiLCJzY3AiOiJteWZpbGVzLnJlYWQgYWxsZmlsZXMucmVhZCBteWZpbGVzLndyaXRlIGFsbGZpbGVzLndyaXRlIG15YXBwZm9sZGVyLndyaXRlIGFsbHNpdGVzLnJlYWQgYWxsc2l0ZXMud3JpdGUgYWxscHJvZmlsZXMucmVhZCIsInNpdGVpZCI6IlpUZG1ORGRtTkdRdE1EWXhOaTAwWVdWaUxUbGhPVEF0T1dFeU1UWTFaV05rTWpGbCIsInRpZCI6IjMwNWZjMDIyLTUyMTctNDkxZi05NDI5LTgwM2RlZTE2MWRkMSIsInR0IjoiMiIsInVwbiI6ImpleXNvbkBhcnNhYmkuY28iLCJ2ZXIiOiJoYXNoZWRwcm9vZnRva2VuIn0.5KJhUHADxchGoLNIOq3IdGVQhaqLi7491bdW1ZK14sQ&ApiVersion=2.0",
];
export const jeyson_projects_2_doc_download = [
  "https://arsabi-my.sharepoint.com/personal/jeyson_arsabi_co/_layouts/15/download.aspx?UniqueId=7bd12fa2-9013-42d6-b82c-72a565a48005&Translate=false&tempauth=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBfZGlzcGxheW5hbWUiOiJHcmFwaCBFeHBsb3JlciIsImFwcGlkIjoiZGU4YmM4YjUtZDlmOS00OGIxLWE4YWQtYjc0OGRhNzI1MDY0IiwiYXVkIjoiMDAwMDAwMDMtMDAwMC0wZmYxLWNlMDAtMDAwMDAwMDAwMDAwL2Fyc2FiaS1teS5zaGFyZXBvaW50LmNvbUAzMDVmYzAyMi01MjE3LTQ5MWYtOTQyOS04MDNkZWUxNjFkZDEiLCJjYWNoZWtleSI6IjBoLmZ8bWVtYmVyc2hpcHwxMDAzMjAwMTAzYWU1MzRkQGxpdmUuY29tIiwiY2lkIjoieTVlK0l4V0V1RzhNWW9rNVExVXRlQT09IiwiZW5kcG9pbnR1cmwiOiIreFFtYkZJbE9QV3NUQXV5NVAzaEg0NEJ0ZDQwRS9YMjlwVEtQSkw1dnRFPSIsImVuZHBvaW50dXJsTGVuZ3RoIjoiMTQ2IiwiZXhwIjoiMTcxNzcyMDY3NCIsImZhbWlseV9uYW1lIjoiQXJzYWJpIiwiZ2l2ZW5fbmFtZSI6IkpleXNvbiIsImlwYWRkciI6IjQwLjEyNi4yNC4xNTIiLCJpc2xvb3BiYWNrIjoiVHJ1ZSIsImlzcyI6IjAwMDAwMDAzLTAwMDAtMGZmMS1jZTAwLTAwMDAwMDAwMDAwMCIsIm5iZiI6IjE3MTc3MTcwNzQiLCJwdWlkIjoiMTAwMzIwMDEwM0FFNTM0RCIsInNjcCI6Im15ZmlsZXMucmVhZCBhbGxmaWxlcy5yZWFkIG15ZmlsZXMud3JpdGUgYWxsZmlsZXMud3JpdGUgbXlhcHBmb2xkZXIud3JpdGUgYWxsc2l0ZXMucmVhZCBhbGxzaXRlcy53cml0ZSBhbGxwcm9maWxlcy5yZWFkIiwic2l0ZWlkIjoiWlRkbU5EZG1OR1F0TURZeE5pMDBZV1ZpTFRsaE9UQXRPV0V5TVRZMVpXTmtNakZsIiwidGlkIjoiMzA1ZmMwMjItNTIxNy00OTFmLTk0MjktODAzZGVlMTYxZGQxIiwidHQiOiIyIiwidXBuIjoiamV5c29uQGFyc2FiaS5jbyIsInZlciI6Imhhc2hlZHByb29mdG9rZW4ifQ.vzcam-SyntCwFwuSivnjwbPMTcWVdFgXGIyychi0A94&ApiVersion=2.0",
  "https://arsabi-my.sharepoint.com/personal/jeyson_arsabi_co/_layouts/15/download.aspx?UniqueId=1e787a38-e6e6-4a0b-b8c7-c9f731e0c64c&Translate=false&tempauth=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBfZGlzcGxheW5hbWUiOiJHcmFwaCBFeHBsb3JlciIsImFwcGlkIjoiZGU4YmM4YjUtZDlmOS00OGIxLWE4YWQtYjc0OGRhNzI1MDY0IiwiYXVkIjoiMDAwMDAwMDMtMDAwMC0wZmYxLWNlMDAtMDAwMDAwMDAwMDAwL2Fyc2FiaS1teS5zaGFyZXBvaW50LmNvbUAzMDVmYzAyMi01MjE3LTQ5MWYtOTQyOS04MDNkZWUxNjFkZDEiLCJjYWNoZWtleSI6IjBoLmZ8bWVtYmVyc2hpcHwxMDAzMjAwMTAzYWU1MzRkQGxpdmUuY29tIiwiY2lkIjoieTVlK0l4V0V1RzhNWW9rNVExVXRlQT09IiwiZW5kcG9pbnR1cmwiOiJPL0dxMnVCV0lmSk5DMWtiNXBWdXVLcXNySnVqYnNXUzhYZ0ZtOUlzaURZPSIsImVuZHBvaW50dXJsTGVuZ3RoIjoiMTQ2IiwiZXhwIjoiMTcxNzcyMDY3NCIsImZhbWlseV9uYW1lIjoiQXJzYWJpIiwiZ2l2ZW5fbmFtZSI6IkpleXNvbiIsImlwYWRkciI6IjQwLjEyNi4yNC4xNTIiLCJpc2xvb3BiYWNrIjoiVHJ1ZSIsImlzcyI6IjAwMDAwMDAzLTAwMDAtMGZmMS1jZTAwLTAwMDAwMDAwMDAwMCIsIm5iZiI6IjE3MTc3MTcwNzQiLCJwdWlkIjoiMTAwMzIwMDEwM0FFNTM0RCIsInNjcCI6Im15ZmlsZXMucmVhZCBhbGxmaWxlcy5yZWFkIG15ZmlsZXMud3JpdGUgYWxsZmlsZXMud3JpdGUgbXlhcHBmb2xkZXIud3JpdGUgYWxsc2l0ZXMucmVhZCBhbGxzaXRlcy53cml0ZSBhbGxwcm9maWxlcy5yZWFkIiwic2l0ZWlkIjoiWlRkbU5EZG1OR1F0TURZeE5pMDBZV1ZpTFRsaE9UQXRPV0V5TVRZMVpXTmtNakZsIiwidGlkIjoiMzA1ZmMwMjItNTIxNy00OTFmLTk0MjktODAzZGVlMTYxZGQxIiwidHQiOiIyIiwidXBuIjoiamV5c29uQGFyc2FiaS5jbyIsInZlciI6Imhhc2hlZHByb29mdG9rZW4ifQ.EUGW7yQqbI0hsI2dys1UPRspUgkQ3UEJHa13GSF58x0&ApiVersion=2.0",
  "https://arsabi-my.sharepoint.com/personal/jeyson_arsabi_co/_layouts/15/download.aspx?UniqueId=3823ea45-47bb-468d-b4da-21f033e2dc53&Translate=false&tempauth=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBfZGlzcGxheW5hbWUiOiJHcmFwaCBFeHBsb3JlciIsImFwcGlkIjoiZGU4YmM4YjUtZDlmOS00OGIxLWE4YWQtYjc0OGRhNzI1MDY0IiwiYXVkIjoiMDAwMDAwMDMtMDAwMC0wZmYxLWNlMDAtMDAwMDAwMDAwMDAwL2Fyc2FiaS1teS5zaGFyZXBvaW50LmNvbUAzMDVmYzAyMi01MjE3LTQ5MWYtOTQyOS04MDNkZWUxNjFkZDEiLCJjYWNoZWtleSI6IjBoLmZ8bWVtYmVyc2hpcHwxMDAzMjAwMTAzYWU1MzRkQGxpdmUuY29tIiwiY2lkIjoieTVlK0l4V0V1RzhNWW9rNVExVXRlQT09IiwiZW5kcG9pbnR1cmwiOiIwMUhCUUNlN05UanlMTHRReUFyTUo4cSsvOTI0YTJ1UXpKYlJQd1E5aWdVPSIsImVuZHBvaW50dXJsTGVuZ3RoIjoiMTQ2IiwiZXhwIjoiMTcxNzcyMDY3NCIsImZhbWlseV9uYW1lIjoiQXJzYWJpIiwiZ2l2ZW5fbmFtZSI6IkpleXNvbiIsImlwYWRkciI6IjQwLjEyNi4yNC4xNTIiLCJpc2xvb3BiYWNrIjoiVHJ1ZSIsImlzcyI6IjAwMDAwMDAzLTAwMDAtMGZmMS1jZTAwLTAwMDAwMDAwMDAwMCIsIm5iZiI6IjE3MTc3MTcwNzQiLCJwdWlkIjoiMTAwMzIwMDEwM0FFNTM0RCIsInNjcCI6Im15ZmlsZXMucmVhZCBhbGxmaWxlcy5yZWFkIG15ZmlsZXMud3JpdGUgYWxsZmlsZXMud3JpdGUgbXlhcHBmb2xkZXIud3JpdGUgYWxsc2l0ZXMucmVhZCBhbGxzaXRlcy53cml0ZSBhbGxwcm9maWxlcy5yZWFkIiwic2l0ZWlkIjoiWlRkbU5EZG1OR1F0TURZeE5pMDBZV1ZpTFRsaE9UQXRPV0V5TVRZMVpXTmtNakZsIiwidGlkIjoiMzA1ZmMwMjItNTIxNy00OTFmLTk0MjktODAzZGVlMTYxZGQxIiwidHQiOiIyIiwidXBuIjoiamV5c29uQGFyc2FiaS5jbyIsInZlciI6Imhhc2hlZHByb29mdG9rZW4ifQ.knhheIbu_SyQAeYVn64ZSqNfrdw7DBGi_5nlQ-Bnh0c&ApiVersion=2.0",
  "https://arsabi-my.sharepoint.com/personal/jeyson_arsabi_co/_layouts/15/download.aspx?UniqueId=f923da32-ea37-4d19-860d-188aa4461502&Translate=false&tempauth=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBfZGlzcGxheW5hbWUiOiJHcmFwaCBFeHBsb3JlciIsImFwcGlkIjoiZGU4YmM4YjUtZDlmOS00OGIxLWE4YWQtYjc0OGRhNzI1MDY0IiwiYXVkIjoiMDAwMDAwMDMtMDAwMC0wZmYxLWNlMDAtMDAwMDAwMDAwMDAwL2Fyc2FiaS1teS5zaGFyZXBvaW50LmNvbUAzMDVmYzAyMi01MjE3LTQ5MWYtOTQyOS04MDNkZWUxNjFkZDEiLCJjYWNoZWtleSI6IjBoLmZ8bWVtYmVyc2hpcHwxMDAzMjAwMTAzYWU1MzRkQGxpdmUuY29tIiwiY2lkIjoieTVlK0l4V0V1RzhNWW9rNVExVXRlQT09IiwiZW5kcG9pbnR1cmwiOiJkRGN6S29WQUJ4M3VLSS81RGY1alV1R05iZ3BZeUEwa0FQSFh1bFFBalRBPSIsImVuZHBvaW50dXJsTGVuZ3RoIjoiMTQ2IiwiZXhwIjoiMTcxNzcyMDY3NCIsImZhbWlseV9uYW1lIjoiQXJzYWJpIiwiZ2l2ZW5fbmFtZSI6IkpleXNvbiIsImlwYWRkciI6IjQwLjEyNi4yNC4xNTIiLCJpc2xvb3BiYWNrIjoiVHJ1ZSIsImlzcyI6IjAwMDAwMDAzLTAwMDAtMGZmMS1jZTAwLTAwMDAwMDAwMDAwMCIsIm5iZiI6IjE3MTc3MTcwNzQiLCJwdWlkIjoiMTAwMzIwMDEwM0FFNTM0RCIsInNjcCI6Im15ZmlsZXMucmVhZCBhbGxmaWxlcy5yZWFkIG15ZmlsZXMud3JpdGUgYWxsZmlsZXMud3JpdGUgbXlhcHBmb2xkZXIud3JpdGUgYWxsc2l0ZXMucmVhZCBhbGxzaXRlcy53cml0ZSBhbGxwcm9maWxlcy5yZWFkIiwic2l0ZWlkIjoiWlRkbU5EZG1OR1F0TURZeE5pMDBZV1ZpTFRsaE9UQXRPV0V5TVRZMVpXTmtNakZsIiwidGlkIjoiMzA1ZmMwMjItNTIxNy00OTFmLTk0MjktODAzZGVlMTYxZGQxIiwidHQiOiIyIiwidXBuIjoiamV5c29uQGFyc2FiaS5jbyIsInZlciI6Imhhc2hlZHByb29mdG9rZW4ifQ.mm_B-ShypLjnHDKljc6CDpGkCz0S1OE0mb6ziyW4pgg&ApiVersion=2.0",
];

export const jeyson_projects_2_doc = [
  "4-Objetivos Semanales Abril.xlsx",
  "5-Objetivos Semanales Mayo.xlsx",
  "Link informe PBI.txt",
  "Fondo 1280x1500 Arsa BI.png",
];

export const jeyson_projects_2_report = ["Inf. Ventas BI.pbix"];
export const jeyson_projects_2_bd = ["TD Asesores.xlsx"];
export const jeyson_projects_2_bd_download = [
  "https://arsabi-my.sharepoint.com/personal/jeyson_arsabi_co/_layouts/15/download.aspx?UniqueId=5806bffa-6014-43f5-b99b-afaa131ea59c&Translate=false&tempauth=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBfZGlzcGxheW5hbWUiOiJHcmFwaCBFeHBsb3JlciIsImFwcGlkIjoiZGU4YmM4YjUtZDlmOS00OGIxLWE4YWQtYjc0OGRhNzI1MDY0IiwiYXVkIjoiMDAwMDAwMDMtMDAwMC0wZmYxLWNlMDAtMDAwMDAwMDAwMDAwL2Fyc2FiaS1teS5zaGFyZXBvaW50LmNvbUAzMDVmYzAyMi01MjE3LTQ5MWYtOTQyOS04MDNkZWUxNjFkZDEiLCJjYWNoZWtleSI6IjBoLmZ8bWVtYmVyc2hpcHwxMDAzMjAwMTAzYWU1MzRkQGxpdmUuY29tIiwiY2lkIjoiZE15azR1OWxMM3ZXRlB1dGlGUFJEZz09IiwiZW5kcG9pbnR1cmwiOiJzbkEvclVIZFdqK3NQQXJXWWMyR1hiblpLeGtFYzYzU0JKcnExUmRGK2NRPSIsImVuZHBvaW50dXJsTGVuZ3RoIjoiMTQ2IiwiZXhwIjoiMTcxNzcyMDgzMCIsImZhbWlseV9uYW1lIjoiQXJzYWJpIiwiZ2l2ZW5fbmFtZSI6IkpleXNvbiIsImlwYWRkciI6IjIwLjE5MC4xNTIuMTUyIiwiaXNsb29wYmFjayI6IlRydWUiLCJpc3MiOiIwMDAwMDAwMy0wMDAwLTBmZjEtY2UwMC0wMDAwMDAwMDAwMDAiLCJuYmYiOiIxNzE3NzE3MjMwIiwicHVpZCI6IjEwMDMyMDAxMDNBRTUzNEQiLCJzY3AiOiJteWZpbGVzLnJlYWQgYWxsZmlsZXMucmVhZCBteWZpbGVzLndyaXRlIGFsbGZpbGVzLndyaXRlIG15YXBwZm9sZGVyLndyaXRlIGFsbHNpdGVzLnJlYWQgYWxsc2l0ZXMud3JpdGUgYWxscHJvZmlsZXMucmVhZCIsInNpdGVpZCI6IlpUZG1ORGRtTkdRdE1EWXhOaTAwWVdWaUxUbGhPVEF0T1dFeU1UWTFaV05rTWpGbCIsInRpZCI6IjMwNWZjMDIyLTUyMTctNDkxZi05NDI5LTgwM2RlZTE2MWRkMSIsInR0IjoiMiIsInVwbiI6ImpleXNvbkBhcnNhYmkuY28iLCJ2ZXIiOiJoYXNoZWRwcm9vZnRva2VuIn0.HGgF6hNgQC0inbH-jbtpzQWBAITwtL1-DYTNp8aDkbk&ApiVersion=2.0",
];

//TODO: LAURA

export const laura_projects_1_doc = [];
export const laura_proyecto_1_report = ["Inf. Ventas BI.pbix"];
export const laura_projects_1_bd = [
  "Resumen Plis BI(MovDav (Conciliación)).csv",
];
export const laura_projects_1_bd_download = [
  "https://arsabi-my.sharepoint.com/personal/laura_arsabi_co/_layouts/15/download.aspx?UniqueId=c26c02cd-3e48-4979-87d8-1e38bd86a229&Translate=false&tempauth=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBfZGlzcGxheW5hbWUiOiJHcmFwaCBFeHBsb3JlciIsImFwcGlkIjoiZGU4YmM4YjUtZDlmOS00OGIxLWE4YWQtYjc0OGRhNzI1MDY0IiwiYXVkIjoiMDAwMDAwMDMtMDAwMC0wZmYxLWNlMDAtMDAwMDAwMDAwMDAwL2Fyc2FiaS1teS5zaGFyZXBvaW50LmNvbUAzMDVmYzAyMi01MjE3LTQ5MWYtOTQyOS04MDNkZWUxNjFkZDEiLCJjYWNoZWtleSI6IjBoLmZ8bWVtYmVyc2hpcHwxMDAzMjAwMTAzYWU1M2FkQGxpdmUuY29tIiwiY2lkIjoiL3FhbWRrMW5vMVlEazNRK1NLRi90dz09IiwiZW5kcG9pbnR1cmwiOiJmckkxdEN1M2E5bTJseTdjejBCWXExc0ZBdVR5QWVOSzJUQmRtbkk5akwwPSIsImVuZHBvaW50dXJsTGVuZ3RoIjoiMTQ1IiwiZXhwIjoiMTcxNzcyMTM1OCIsImZhbWlseV9uYW1lIjoiQXJzYWJpIiwiZ2l2ZW5fbmFtZSI6IkxhdXJhIiwiaXBhZGRyIjoiNDAuMTI2LjI0LjE1MiIsImlzbG9vcGJhY2siOiJUcnVlIiwiaXNzIjoiMDAwMDAwMDMtMDAwMC0wZmYxLWNlMDAtMDAwMDAwMDAwMDAwIiwibmJmIjoiMTcxNzcxNzc1OCIsInB1aWQiOiIxMDAzMjAwMTAzQUU1M0FEIiwic2NwIjoibXlmaWxlcy5yZWFkIGFsbGZpbGVzLnJlYWQgbXlmaWxlcy53cml0ZSBhbGxmaWxlcy53cml0ZSBteWFwcGZvbGRlci53cml0ZSBhbGxzaXRlcy5yZWFkIGFsbHNpdGVzLndyaXRlIGFsbHByb2ZpbGVzLnJlYWQiLCJzaWduaW5fc3RhdGUiOiJbXCJrbXNpXCJdIiwic2l0ZWlkIjoiWTJJM1lXTTFaVGN0TXpZeU5TMDBNRGcwTFRoaVlUZ3RNMlJoWXpRMU5UZzFNMkZoIiwidGlkIjoiMzA1ZmMwMjItNTIxNy00OTFmLTk0MjktODAzZGVlMTYxZGQxIiwidHQiOiIyIiwidXBuIjoibGF1cmFAYXJzYWJpLmNvIiwidmVyIjoiaGFzaGVkcHJvb2Z0b2tlbiJ9.0nOPnEJaZvC6UCpQ0p_lFuMkDc7mAz1ZhKH74DOZYko&ApiVersion=2.0",
];
export const laura_projects_1_bd_view = [
  "https://arsabi-my.sharepoint.com/personal/laura_arsabi_co/_layouts/15/Doc.aspx?sourcedoc=%7BC26C02CD-3E48-4979-87D8-1E38BD86A229%7D&file=Resumen%20Plis%20BI(MovDav%20(Conciliaci%C3%B3n)).csv&action=default&mobileredirect=true",
];

//TODO: JUAN
export const juan_projects_1_doc = ["Ver imagen.txt"];
export const juan_projects_1_doc_view = [
  "https://arsabi-my.sharepoint.com/personal/juan_quesada_arsabi_co/Documents/Proyectos/1.Lanuza/DOC/Ver%20imagen.txt",
];
export const juan_projects_1_doc_dowload = [
  "https://arsabi-my.sharepoint.com/personal/juan_quesada_arsabi_co/_layouts/15/download.aspx?UniqueId=92b4d378-63c6-4e99-b593-def72f74ed61&Translate=false&tempauth=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBfZGlzcGxheW5hbWUiOiJHcmFwaCBFeHBsb3JlciIsImFwcGlkIjoiZGU4YmM4YjUtZDlmOS00OGIxLWE4YWQtYjc0OGRhNzI1MDY0IiwiYXVkIjoiMDAwMDAwMDMtMDAwMC0wZmYxLWNlMDAtMDAwMDAwMDAwMDAwL2Fyc2FiaS1teS5zaGFyZXBvaW50LmNvbUAzMDVmYzAyMi01MjE3LTQ5MWYtOTQyOS04MDNkZWUxNjFkZDEiLCJjYWNoZWtleSI6IjBoLmZ8bWVtYmVyc2hpcHwxMDAzMjAwMzZiODJkODhlQGxpdmUuY29tIiwiY2lkIjoiek15ZGZUU1lFRU9PRnZnMnhaUE5Fdz09IiwiZW5kcG9pbnR1cmwiOiJ3cjZHcnNwUnpLSThFTTNkUk1PeW9BK1h5cXR0UDJFbmMxMTh3OTk5bXhzPSIsImVuZHBvaW50dXJsTGVuZ3RoIjoiMTUyIiwiZXhwIjoiMTcxNzcyMjUzMSIsImZhbWlseV9uYW1lIjoiUXVlc2FkYSIsImdpdmVuX25hbWUiOiJKdWFuIiwiaXBhZGRyIjoiNDAuMTI2LjI0LjE1MiIsImlzbG9vcGJhY2siOiJUcnVlIiwiaXNzIjoiMDAwMDAwMDMtMDAwMC0wZmYxLWNlMDAtMDAwMDAwMDAwMDAwIiwibmJmIjoiMTcxNzcxODkzMSIsInB1aWQiOiIxMDAzMjAwMzZCODJEODhFIiwic2NwIjoibXlmaWxlcy5yZWFkIGFsbGZpbGVzLnJlYWQgYWxsc2l0ZXMucmVhZCBhbGxzaXRlcy53cml0ZSBhbGxwcm9maWxlcy5yZWFkIiwic2lnbmluX3N0YXRlIjoiW1wia21zaVwiXSIsInNpdGVpZCI6Ik1URmxZak16TkdJdE9XVTRPQzAwTVRRMExXSTRNalF0TlROaE5tVXdNMlJpT0RsbSIsInRpZCI6IjMwNWZjMDIyLTUyMTctNDkxZi05NDI5LTgwM2RlZTE2MWRkMSIsInR0IjoiMiIsInVwbiI6Imp1YW4ucXVlc2FkYUBhcnNhYmkuY28iLCJ2ZXIiOiJoYXNoZWRwcm9vZnRva2VuIn0.mhkDXUr-mVIxwcX9o_vLu84849kSZRaxW275zE4Zipk&ApiVersion=2.0",
];
export const juan_projects_1_report = ["Lanuza Group BI.pbix"];
export const juan_projects_1_bd = ["Link Equipos.xlsx"];
export const juan_projects_1_bd_view = [
  "https://arsabi-my.sharepoint.com/personal/juan_quesada_arsabi_co/_layouts/15/Doc.aspx?sourcedoc=%7B6B8C5B07-CEBF-4ABB-A9B6-EF6169840A10%7D&file=Link%20Equipos.xlsx&action=default&mobileredirect=true",
];
export const juan_projects_2_bd_view = [
  "https://arsabi-my.sharepoint.com/personal/juan_quesada_arsabi_co/_layouts/15/Doc.aspx?sourcedoc=%7B7F593DD4-5726-4858-A98B-50BEC6DD7AD4%7D&file=TH%20PQRS%20Informes%20de%20visitas.xlsx&action=default&mobileredirect=true",
  "https://arsabi-my.sharepoint.com/personal/juan_quesada_arsabi_co/_layouts/15/Doc.aspx?sourcedoc=%7B955A41DF-D323-4FC3-80FD-0E78C10956C5%7D&file=TH%20Informes%20de%20visitas.xlsx&action=default&mobileredirect=true",
  "https://arsabi-my.sharepoint.com/personal/juan_quesada_arsabi_co/_layouts/15/Doc.aspx?sourcedoc=%7BC92F1A11-5BDD-4500-8719-E85C1C0717F5%7D&file=TD%20Inventario%20DB.xlsx&action=default&mobileredirect=true",
  "https://arsabi-my.sharepoint.com/personal/juan_quesada_arsabi_co/_layouts/15/Doc.aspx?sourcedoc=%7BDA499448-304D-40E4-BC6F-A2F74F01ECD5%7D&file=TD%20Busqueda%20de%20terceros.xlsx&action=default&mobileredirect=true",
];

export const juan_projects_2_doc = ["TD Busqueda de terceros.xlsx"];
export const juan_projects_2_doc_view = [
  "https://arsabi-my.sharepoint.com/personal/juan_quesada_arsabi_co/_layouts/15/Doc.aspx?sourcedoc=%7BDA499448-304D-40E4-BC6F-A2F74F01ECD5%7D&file=TD%20Busqueda%20de%20terceros.xlsx&action=default&mobileredirect=true",
];
export const juan_projects_2_doc_dowload = [
  "https://arsabi-my.sharepoint.com/personal/juan_quesada_arsabi_co/_layouts/15/download.aspx?UniqueId=da499448-304d-40e4-bc6f-a2f74f01ecd5&Translate=false&tempauth=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBfZGlzcGxheW5hbWUiOiJHcmFwaCBFeHBsb3JlciIsImFwcGlkIjoiZGU4YmM4YjUtZDlmOS00OGIxLWE4YWQtYjc0OGRhNzI1MDY0IiwiYXVkIjoiMDAwMDAwMDMtMDAwMC0wZmYxLWNlMDAtMDAwMDAwMDAwMDAwL2Fyc2FiaS1teS5zaGFyZXBvaW50LmNvbUAzMDVmYzAyMi01MjE3LTQ5MWYtOTQyOS04MDNkZWUxNjFkZDEiLCJjYWNoZWtleSI6IjBoLmZ8bWVtYmVyc2hpcHwxMDAzMjAwMzZiODJkODhlQGxpdmUuY29tIiwiY2lkIjoiN21SWkR6QnZoVWlUbEpUaGZZSVFTZz09IiwiZW5kcG9pbnR1cmwiOiJHOFp5TkdXRC95ek5wMWRnQXFPcGhoOVkveE1SMzlSOVpiNUE0WjY5NWg0PSIsImVuZHBvaW50dXJsTGVuZ3RoIjoiMTUyIiwiZXhwIjoiMTcxNzcyMjc1NSIsImZhbWlseV9uYW1lIjoiUXVlc2FkYSIsImdpdmVuX25hbWUiOiJKdWFuIiwiaXBhZGRyIjoiNDAuMTI2LjI0LjE1MiIsImlzbG9vcGJhY2siOiJUcnVlIiwiaXNzIjoiMDAwMDAwMDMtMDAwMC0wZmYxLWNlMDAtMDAwMDAwMDAwMDAwIiwibmJmIjoiMTcxNzcxOTE1NSIsInB1aWQiOiIxMDAzMjAwMzZCODJEODhFIiwic2NwIjoibXlmaWxlcy5yZWFkIGFsbGZpbGVzLnJlYWQgYWxsc2l0ZXMucmVhZCBhbGxzaXRlcy53cml0ZSBhbGxwcm9maWxlcy5yZWFkIiwic2lnbmluX3N0YXRlIjoiW1wia21zaVwiXSIsInNpdGVpZCI6Ik1URmxZak16TkdJdE9XVTRPQzAwTVRRMExXSTRNalF0TlROaE5tVXdNMlJpT0RsbSIsInRpZCI6IjMwNWZjMDIyLTUyMTctNDkxZi05NDI5LTgwM2RlZTE2MWRkMSIsInR0IjoiMiIsInVwbiI6Imp1YW4ucXVlc2FkYUBhcnNhYmkuY28iLCJ2ZXIiOiJoYXNoZWRwcm9vZnRva2VuIn0.JcU9zkULuRNMIO4zzfAocD6l-kObVPbrfznmkUj4uzU&ApiVersion=2.0",
];
export const juan_projects_2_report = ["Inf. Lanuza Group - Visitas BI.pbix"];
export const juan_projects_2_bd = [
  "TH PQRS Informes de visitas.xlsx",
  "TH Informes de visitas.xlsx",
  "TD Inventario DB.xlsx",
  "TD Busqueda de terceros.xlsx",
];
export const juan_projects_2_bd_download = [
  "https://arsabi-my.sharepoint.com/personal/juan_quesada_arsabi_co/_layouts/15/download.aspx?UniqueId=7f593dd4-5726-4858-a98b-50bec6dd7ad4&Translate=false&tempauth=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBfZGlzcGxheW5hbWUiOiJHcmFwaCBFeHBsb3JlciIsImFwcGlkIjoiZGU4YmM4YjUtZDlmOS00OGIxLWE4YWQtYjc0OGRhNzI1MDY0IiwiYXVkIjoiMDAwMDAwMDMtMDAwMC0wZmYxLWNlMDAtMDAwMDAwMDAwMDAwL2Fyc2FiaS1teS5zaGFyZXBvaW50LmNvbUAzMDVmYzAyMi01MjE3LTQ5MWYtOTQyOS04MDNkZWUxNjFkZDEiLCJjYWNoZWtleSI6IjBoLmZ8bWVtYmVyc2hpcHwxMDAzMjAwMzZiODJkODhlQGxpdmUuY29tIiwiY2lkIjoiQzJjSnN3R1h0RXVDdGl6ak5NZnIyQT09IiwiZW5kcG9pbnR1cmwiOiJTMkNxMHNJQkJ1YmhSd1piQmEzbHg1UVN5eVZVK1RrVDZxSkJuV21CVWxNPSIsImVuZHBvaW50dXJsTGVuZ3RoIjoiMTUyIiwiZXhwIjoiMTcxNzcyMjg4OSIsImZhbWlseV9uYW1lIjoiUXVlc2FkYSIsImdpdmVuX25hbWUiOiJKdWFuIiwiaXBhZGRyIjoiNDAuMTI2LjI0LjE1MiIsImlzbG9vcGJhY2siOiJUcnVlIiwiaXNzIjoiMDAwMDAwMDMtMDAwMC0wZmYxLWNlMDAtMDAwMDAwMDAwMDAwIiwibmJmIjoiMTcxNzcxOTI4OSIsInB1aWQiOiIxMDAzMjAwMzZCODJEODhFIiwic2NwIjoibXlmaWxlcy5yZWFkIGFsbGZpbGVzLnJlYWQgYWxsc2l0ZXMucmVhZCBhbGxzaXRlcy53cml0ZSBhbGxwcm9maWxlcy5yZWFkIiwic2lnbmluX3N0YXRlIjoiW1wia21zaVwiXSIsInNpdGVpZCI6Ik1URmxZak16TkdJdE9XVTRPQzAwTVRRMExXSTRNalF0TlROaE5tVXdNMlJpT0RsbSIsInRpZCI6IjMwNWZjMDIyLTUyMTctNDkxZi05NDI5LTgwM2RlZTE2MWRkMSIsInR0IjoiMiIsInVwbiI6Imp1YW4ucXVlc2FkYUBhcnNhYmkuY28iLCJ2ZXIiOiJoYXNoZWRwcm9vZnRva2VuIn0.7l2GbXA8iN8mnRwpNOLcnJRFkKJ4h0W7nxB8waVpgZk&ApiVersion=2.0",
  "https://arsabi-my.sharepoint.com/personal/juan_quesada_arsabi_co/_layouts/15/download.aspx?UniqueId=955a41df-d323-4fc3-80fd-0e78c10956c5&Translate=false&tempauth=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBfZGlzcGxheW5hbWUiOiJHcmFwaCBFeHBsb3JlciIsImFwcGlkIjoiZGU4YmM4YjUtZDlmOS00OGIxLWE4YWQtYjc0OGRhNzI1MDY0IiwiYXVkIjoiMDAwMDAwMDMtMDAwMC0wZmYxLWNlMDAtMDAwMDAwMDAwMDAwL2Fyc2FiaS1teS5zaGFyZXBvaW50LmNvbUAzMDVmYzAyMi01MjE3LTQ5MWYtOTQyOS04MDNkZWUxNjFkZDEiLCJjYWNoZWtleSI6IjBoLmZ8bWVtYmVyc2hpcHwxMDAzMjAwMzZiODJkODhlQGxpdmUuY29tIiwiY2lkIjoiQzJjSnN3R1h0RXVDdGl6ak5NZnIyQT09IiwiZW5kcG9pbnR1cmwiOiJ1SzhpSjBBZEJtcmVPaDZEQlgzeEl2K3haMmhsbGlHTVZSTHFjVHk4UFNJPSIsImVuZHBvaW50dXJsTGVuZ3RoIjoiMTUyIiwiZXhwIjoiMTcxNzcyMjg4OSIsImZhbWlseV9uYW1lIjoiUXVlc2FkYSIsImdpdmVuX25hbWUiOiJKdWFuIiwiaXBhZGRyIjoiNDAuMTI2LjI0LjE1MiIsImlzbG9vcGJhY2siOiJUcnVlIiwiaXNzIjoiMDAwMDAwMDMtMDAwMC0wZmYxLWNlMDAtMDAwMDAwMDAwMDAwIiwibmJmIjoiMTcxNzcxOTI4OSIsInB1aWQiOiIxMDAzMjAwMzZCODJEODhFIiwic2NwIjoibXlmaWxlcy5yZWFkIGFsbGZpbGVzLnJlYWQgYWxsc2l0ZXMucmVhZCBhbGxzaXRlcy53cml0ZSBhbGxwcm9maWxlcy5yZWFkIiwic2lnbmluX3N0YXRlIjoiW1wia21zaVwiXSIsInNpdGVpZCI6Ik1URmxZak16TkdJdE9XVTRPQzAwTVRRMExXSTRNalF0TlROaE5tVXdNMlJpT0RsbSIsInRpZCI6IjMwNWZjMDIyLTUyMTctNDkxZi05NDI5LTgwM2RlZTE2MWRkMSIsInR0IjoiMiIsInVwbiI6Imp1YW4ucXVlc2FkYUBhcnNhYmkuY28iLCJ2ZXIiOiJoYXNoZWRwcm9vZnRva2VuIn0.feNCLklYWd7tsXwZXKw__zJ_gj_zuXQZtIV89tGL27M&ApiVersion=2.0",
  "https://arsabi-my.sharepoint.com/personal/juan_quesada_arsabi_co/_layouts/15/download.aspx?UniqueId=c92f1a11-5bdd-4500-8719-e85c1c0717f5&Translate=false&tempauth=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBfZGlzcGxheW5hbWUiOiJHcmFwaCBFeHBsb3JlciIsImFwcGlkIjoiZGU4YmM4YjUtZDlmOS00OGIxLWE4YWQtYjc0OGRhNzI1MDY0IiwiYXVkIjoiMDAwMDAwMDMtMDAwMC0wZmYxLWNlMDAtMDAwMDAwMDAwMDAwL2Fyc2FiaS1teS5zaGFyZXBvaW50LmNvbUAzMDVmYzAyMi01MjE3LTQ5MWYtOTQyOS04MDNkZWUxNjFkZDEiLCJjYWNoZWtleSI6IjBoLmZ8bWVtYmVyc2hpcHwxMDAzMjAwMzZiODJkODhlQGxpdmUuY29tIiwiY2lkIjoiQzJjSnN3R1h0RXVDdGl6ak5NZnIyQT09IiwiZW5kcG9pbnR1cmwiOiJyS3N0dCt1cVJYd2Q3ZEdhTisvcWNtQi9paVpoelBqMHhHeElqaUZPNU9nPSIsImVuZHBvaW50dXJsTGVuZ3RoIjoiMTUyIiwiZXhwIjoiMTcxNzcyMjg4OSIsImZhbWlseV9uYW1lIjoiUXVlc2FkYSIsImdpdmVuX25hbWUiOiJKdWFuIiwiaXBhZGRyIjoiNDAuMTI2LjI0LjE1MiIsImlzbG9vcGJhY2siOiJUcnVlIiwiaXNzIjoiMDAwMDAwMDMtMDAwMC0wZmYxLWNlMDAtMDAwMDAwMDAwMDAwIiwibmJmIjoiMTcxNzcxOTI4OSIsInB1aWQiOiIxMDAzMjAwMzZCODJEODhFIiwic2NwIjoibXlmaWxlcy5yZWFkIGFsbGZpbGVzLnJlYWQgYWxsc2l0ZXMucmVhZCBhbGxzaXRlcy53cml0ZSBhbGxwcm9maWxlcy5yZWFkIiwic2lnbmluX3N0YXRlIjoiW1wia21zaVwiXSIsInNpdGVpZCI6Ik1URmxZak16TkdJdE9XVTRPQzAwTVRRMExXSTRNalF0TlROaE5tVXdNMlJpT0RsbSIsInRpZCI6IjMwNWZjMDIyLTUyMTctNDkxZi05NDI5LTgwM2RlZTE2MWRkMSIsInR0IjoiMiIsInVwbiI6Imp1YW4ucXVlc2FkYUBhcnNhYmkuY28iLCJ2ZXIiOiJoYXNoZWRwcm9vZnRva2VuIn0.WZ_MU9OQsiWXSuPw4RfaDaw-RUZQVl_vIKbnaznXuQ0&ApiVersion=2.0",
  "https://arsabi-my.sharepoint.com/personal/juan_quesada_arsabi_co/_layouts/15/download.aspx?UniqueId=da499448-304d-40e4-bc6f-a2f74f01ecd5&Translate=false&tempauth=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBfZGlzcGxheW5hbWUiOiJHcmFwaCBFeHBsb3JlciIsImFwcGlkIjoiZGU4YmM4YjUtZDlmOS00OGIxLWE4YWQtYjc0OGRhNzI1MDY0IiwiYXVkIjoiMDAwMDAwMDMtMDAwMC0wZmYxLWNlMDAtMDAwMDAwMDAwMDAwL2Fyc2FiaS1teS5zaGFyZXBvaW50LmNvbUAzMDVmYzAyMi01MjE3LTQ5MWYtOTQyOS04MDNkZWUxNjFkZDEiLCJjYWNoZWtleSI6IjBoLmZ8bWVtYmVyc2hpcHwxMDAzMjAwMzZiODJkODhlQGxpdmUuY29tIiwiY2lkIjoiQzJjSnN3R1h0RXVDdGl6ak5NZnIyQT09IiwiZW5kcG9pbnR1cmwiOiJHOFp5TkdXRC95ek5wMWRnQXFPcGhoOVkveE1SMzlSOVpiNUE0WjY5NWg0PSIsImVuZHBvaW50dXJsTGVuZ3RoIjoiMTUyIiwiZXhwIjoiMTcxNzcyMjg4OSIsImZhbWlseV9uYW1lIjoiUXVlc2FkYSIsImdpdmVuX25hbWUiOiJKdWFuIiwiaXBhZGRyIjoiNDAuMTI2LjI0LjE1MiIsImlzbG9vcGJhY2siOiJUcnVlIiwiaXNzIjoiMDAwMDAwMDMtMDAwMC0wZmYxLWNlMDAtMDAwMDAwMDAwMDAwIiwibmJmIjoiMTcxNzcxOTI4OSIsInB1aWQiOiIxMDAzMjAwMzZCODJEODhFIiwic2NwIjoibXlmaWxlcy5yZWFkIGFsbGZpbGVzLnJlYWQgYWxsc2l0ZXMucmVhZCBhbGxzaXRlcy53cml0ZSBhbGxwcm9maWxlcy5yZWFkIiwic2lnbmluX3N0YXRlIjoiW1wia21zaVwiXSIsInNpdGVpZCI6Ik1URmxZak16TkdJdE9XVTRPQzAwTVRRMExXSTRNalF0TlROaE5tVXdNMlJpT0RsbSIsInRpZCI6IjMwNWZjMDIyLTUyMTctNDkxZi05NDI5LTgwM2RlZTE2MWRkMSIsInR0IjoiMiIsInVwbiI6Imp1YW4ucXVlc2FkYUBhcnNhYmkuY28iLCJ2ZXIiOiJoYXNoZWRwcm9vZnRva2VuIn0.x0Ru7mY8EW8RmQJW4SL3C-1vcFCzTGdNloU_aYzEEfw&ApiVersion=2.0",
];

export const juan_projects_1_bd_download = [
  "https://arsabi-my.sharepoint.com/personal/juan_quesada_arsabi_co/_layouts/15/download.aspx?UniqueId=6b8c5b07-cebf-4abb-a9b6-ef6169840a10&Translate=false&tempauth=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBfZGlzcGxheW5hbWUiOiJHcmFwaCBFeHBsb3JlciIsImFwcGlkIjoiZGU4YmM4YjUtZDlmOS00OGIxLWE4YWQtYjc0OGRhNzI1MDY0IiwiYXVkIjoiMDAwMDAwMDMtMDAwMC0wZmYxLWNlMDAtMDAwMDAwMDAwMDAwL2Fyc2FiaS1teS5zaGFyZXBvaW50LmNvbUAzMDVmYzAyMi01MjE3LTQ5MWYtOTQyOS04MDNkZWUxNjFkZDEiLCJjYWNoZWtleSI6IjBoLmZ8bWVtYmVyc2hpcHwxMDAzMjAwMzZiODJkODhlQGxpdmUuY29tIiwiY2lkIjoiVUpaYkhvWEdaVWFFVmtnSTU4MEhhdz09IiwiZW5kcG9pbnR1cmwiOiIxK1RLV0lLRVhrQ2tZR3NOQno3aDJWbTMrYlIwdHRST2JmT1ZlcUJVdzhBPSIsImVuZHBvaW50dXJsTGVuZ3RoIjoiMTUyIiwiZXhwIjoiMTcxNzcyMjY0OSIsImZhbWlseV9uYW1lIjoiUXVlc2FkYSIsImdpdmVuX25hbWUiOiJKdWFuIiwiaXBhZGRyIjoiNDAuMTI2LjI0LjE1MiIsImlzbG9vcGJhY2siOiJUcnVlIiwiaXNzIjoiMDAwMDAwMDMtMDAwMC0wZmYxLWNlMDAtMDAwMDAwMDAwMDAwIiwibmJmIjoiMTcxNzcxOTA0OSIsInB1aWQiOiIxMDAzMjAwMzZCODJEODhFIiwic2NwIjoibXlmaWxlcy5yZWFkIGFsbGZpbGVzLnJlYWQgYWxsc2l0ZXMucmVhZCBhbGxzaXRlcy53cml0ZSBhbGxwcm9maWxlcy5yZWFkIiwic2lnbmluX3N0YXRlIjoiW1wia21zaVwiXSIsInNpdGVpZCI6Ik1URmxZak16TkdJdE9XVTRPQzAwTVRRMExXSTRNalF0TlROaE5tVXdNMlJpT0RsbSIsInRpZCI6IjMwNWZjMDIyLTUyMTctNDkxZi05NDI5LTgwM2RlZTE2MWRkMSIsInR0IjoiMiIsInVwbiI6Imp1YW4ucXVlc2FkYUBhcnNhYmkuY28iLCJ2ZXIiOiJoYXNoZWRwcm9vZnRva2VuIn0.G89Cwxe1XTECF-VlrLviAYBqkVmYTXXUxEo3X2dm_pI&ApiVersion=2.0",
];
