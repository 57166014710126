import { toast } from "sonner";

import { createCustumerTicket } from "api/tickets/route";
import { supabase } from "utils/SupabaseClient";


export async function getImpacts({ supabase }) {
  const { data, error } = await supabase
    .from("dim_tipo_impactos")
    .select("id, impacto");
  if (error) {
    toast.error("Error obteniendo servicios");
    return [];
  }
  return data;
}


// guardar una incidencia 

export async function saveCustumerProblem({
  ticketTypeId,
  projectId,
  summary,
  description,
  serviceId,
  impactValue,
  reason,
  solution,                                           
  steps,
  attachment
}) {

    const dataTicket = await createCustumerTicket({
      ticketTypeId,
      serviceId,
      projectId,
      summary: summary,
      description: description
    })
    

  const { data, error } = await supabase
    .from("ticket_fields")
    .insert([
      {
        ticket_id: dataTicket[0],
        field_definition_id: 7,
        field_value: impactValue,
        created_by: dataTicket[1],
        modified_by:  dataTicket[1]
      },
      {
        ticket_id: dataTicket[0],
        field_definition_id: 19,
        field_value: reason,
        created_by: dataTicket[1],
        modified_by:  dataTicket[1]
      },
      {
        ticket_id: dataTicket[0],
        field_definition_id: 20,
        field_value:solution,
        created_by: dataTicket[1],
        modified_by:  dataTicket[1]
      },
      {
        ticket_id: dataTicket[0],
        field_definition_id: 18,
        field_value: steps,
        created_by: dataTicket[1],
        modified_by:  dataTicket[1]
      },
      {
        ticket_id: dataTicket[0],
        field_definition_id: 11,
        field_value: attachment,
        created_by: dataTicket[1],
        modified_by:  dataTicket[1]
      },
    ])
  if (error) {
    toast.error(`Error al guardar la incidencia`);
  }
}













export async function saveProblem({
  supabase,
  selectedService,
  selectedProject,
  summary,
  description,
  impact,
  reason,
  solution,
  steps,
}) {
  const {
    data: { user },
  } = await supabase.auth.getUser();

  const { data, error } = await supabase
    .from("th_problemas")
    .insert([
      {
        servicio_id: selectedService,
        proyecto_id: selectedProject,
        resumen: summary,
        descripcion: description,
        impacto_id: impact,
        causa_principal: reason,
        solucion: solution,
        paso_error: steps,
        adjuntos: false,
        aprobacion: false,
        created_by: user.id,
        estado_id: 1,
        flujo_id: 4,
      },
    ])
    .select();
  if (error) {
    toast.error(`Error al guardar el problema`);
  }
}
