import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { toast } from "sonner";

import { getTicketDetails } from "hooks/commonData";
import { ViewChangeUI } from "./ViewChangeUI";

export function ViewChange() {
  const { issue } = useParams();
  const documents = [
    {
      name: "Document.pdf",
      size: "1.5MB",
    },
    {
      name: "Document2.pdf",
      size: "2.5MB",
    },
  ];

  const [ticketBasic,setTicketBasic]= useState()
  const [ticketFields,setTicketFields]= useState([])

  useEffect(() => {
    getTicketDetails( issue, "basic").then((data)=>{
      setTicketBasic(data)      
    })
    getTicketDetails( issue, "fields").then((data)=>{
      setTicketFields(data.ticket_fields)
    })     
  }, []);
  
  

  if (!ticketBasic||!ticketFields) {
    return <p>Cargando...</p>;
  } else if (ticketBasic||ticketFields) {
    return (
      <ViewChangeUI
        summary={ticketBasic.title}
        description={ticketBasic.description}
        createdBy={ticketBasic.created_by.email}
        project={ticketBasic.project_id.project_name}
        status={ticketBasic.status_id.status_name}
        service={ticketBasic.service_id.item_value}
        urgency={ticketFields.find(e=>e.field_definition_id.id===10).field_value}
        reason={ticketFields.find(e=>e.field_definition_id.id===8).field_value}
        impactCustomers={ticketFields.find(e=>e.field_definition_id.id===6).field_value}
        additionalInfo={ticketFields.find(e=>e.field_definition_id.id===16).field_value}
        documents={documents}
      />
    );
  }
}
