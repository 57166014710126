import {
  Sidebar,
  SidebarItem,
  sideBarItems,
} from "components/ServiceDesk/SideBar";

import NavBar from "components/ServiceDesk/NavBar";
import { Incidents } from "./incidents";

export function LayoutPMO({ supabaseClient }) {
  const modules = sideBarItems;

  return (
    <div className="flex">
      <Sidebar user={`Juan Quesada`} email={"juan.quesada@arsabi.co"}>
        {modules.map((item, index) => (
          <SidebarItem
            key={index}
            icon={item.icon}
            text={item.text}
            active={item.active}
            alert={item.alert}
            to={item.to}
          />
        ))}
      </Sidebar>
      <main className="grow bg-[#FBFCFC]">
        <NavBar supabase={supabaseClient} />
        <div className="container mx-auto p-5">{/*<Incidents />*/}</div>
      </main>
    </div>
  );
}
