import { toast } from "sonner";

import { createCustumerTicket } from "api/tickets/route";
import { supabase } from "utils/SupabaseClient";


// guardar un requerimiento

export async function saveCustumerRequirement({
  ticketTypeId,
  projectId,
  summary,
  description,
  serviceId,
  justify,
  profile,
  objective,
  benefit,
  additionalInfo
}) {


    const dataTicket = await createCustumerTicket({
      ticketTypeId,
      serviceId,
      projectId,
      summary,
      description
    })
    
  const { data, error } = await supabase
    .from("ticket_fields")
    .insert([
      {
        ticket_id: dataTicket[0],
        field_definition_id: 12,
        field_value: justify,
        created_by: dataTicket[1],
        modified_by:  dataTicket[1]
      },
      {
        ticket_id: dataTicket[0],
        field_definition_id: 13,
        field_value: profile,
        created_by: dataTicket[1],
        modified_by:  dataTicket[1]
      },
      {
        ticket_id: dataTicket[0],
        field_definition_id: 14,
        field_value: objective,
        created_by: dataTicket[1],
        modified_by:  dataTicket[1]
      },
      {
        ticket_id: dataTicket[0],
        field_definition_id: 15,
        field_value: benefit,
        created_by: dataTicket[1],
        modified_by:  dataTicket[1]
      },
      {
        ticket_id: dataTicket[0],
        field_definition_id: 16,
        field_value: additionalInfo,
        created_by: dataTicket[1],
        modified_by:  dataTicket[1]
      },
    ])
  if (error) {
    toast.error(`Error al guardar el requerimiento`);
  }
}
