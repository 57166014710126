function Title({ text }) {
  return (
    <th scope="col" class="px-6 py-3">
      {text}
    </th>
  );
}

function RowColumn({ text }) {
  return <td class="px-6 py-4">{text}</td>;
}

export function Row({ columns }) {
  return (
    <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
      {columns.map((column, index) => (
        <RowColumn key={index} text={column} />
      ))}
    </tr>
  );
}

export function Table({ titles, children }) {
  return (
    <div class="relative overflow-x-auto">
      <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            {titles.map((title, index) => (
              <Title key={index} text={title} />
            ))}
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </table>
    </div>
  );
}
