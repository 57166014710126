import { toast } from "sonner";


import { createCustumerTicket } from "api/tickets/route";
import { supabase } from "utils/SupabaseClient";


export async function saveCustumerChange({
  ticketTypeId,
  projectId,
  summary,
  description,
  serviceId,
  urgencyValue,
  reasonValue,
  customerImpactValue,
  additionalInfo
}) {

  

    const dataTicket = await createCustumerTicket({
      ticketTypeId,
      serviceId,
      projectId,
      summary,
      description
    })
    

  const { data, error } = await supabase
    .from("ticket_fields")
    .insert([
      {
        ticket_id: dataTicket[0],
        field_definition_id: 10,
        field_value: urgencyValue,
        created_by: dataTicket[1],
        modified_by:  dataTicket[1]
      },
      {
        ticket_id: dataTicket[0],
        field_definition_id: 8,
        field_value: reasonValue,
        created_by: dataTicket[1],
        modified_by:  dataTicket[1]
      },
      {
        ticket_id: dataTicket[0],
        field_definition_id: 6,
        field_value: customerImpactValue,
        created_by: dataTicket[1],
        modified_by:  dataTicket[1]
      },
      {
        ticket_id: dataTicket[0],
        field_definition_id: 16,
        field_value: additionalInfo,
        created_by: dataTicket[1],
        modified_by:  dataTicket[1]
      },
    ])
  if (error) {
    toast.error(`Error al guardar la incidencia`);
  }
}
