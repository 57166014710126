import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getTicketDetails } from "hooks/commonData";
import { ViewRequerimentUI } from "./ViewRequerimentUI";

export function ViewRequeriment() {
  const { issue } = useParams();

  const comments = [
    {
      user: "Usuario",
      date: "Fecha",
      comment: "Comentario",
    },
    {
      user: "Usuario",
      date: "Fecha",
      comment: "Comentario",
    },
  ];

  const documents = [
    {
      name: "Document.pdf",
      size: "1.5MB",
    },
    {
      name: "Document2.pdf",
      size: "2.5MB",
    },
  ];

  const [ticketBasic, setTicketBasic] = useState();
  const [ticketFields, setTicketFields] = useState([]);

  useEffect(() => {
    getTicketDetails(issue, "basic").then((data) => {
      setTicketBasic(data);
    });
    getTicketDetails(issue, "fields").then((data) => {
      setTicketFields(data.ticket_fields);
    });
  }, []);

  if (!ticketBasic || !ticketFields) {
    return <p>Cargando...</p>;
  } else if (ticketBasic || ticketFields) {
    return (
      <ViewRequerimentUI
        summary={ticketBasic.title}
        createdBy={ticketBasic.created_by.email}
        project={ticketBasic.project_id.project_name}
        status={ticketBasic.status_id.status_name}
        service={ticketBasic.service_id.item_value}
        justification={
          ticketFields.find((e) => e.field_definition_id.id === 12).field_value
        }
        how={
          ticketFields.find((e) => e.field_definition_id.id === 13).field_value
        }
        iWantTo={
          ticketFields.find((e) => e.field_definition_id.id === 14).field_value
        }
        forWhat={
          ticketFields.find((e) => e.field_definition_id.id === 15).field_value
        }
        additionalInfo={
          ticketFields.find((e) => e.field_definition_id.id === 16).field_value
        }
        documents={documents}
        comments={comments}
      />
    );
  }
}
