import { Card } from "components/ServiceDesk/Card";
import { Table, Row } from "components/ServiceDesk/Table";

export function Incidents() {
  const titles = [
    "ID",
    "Empresa",
    "Proyecto",
    "Servicio",
    "Creado por",
    "Tipo",
    "Estado",
    "Resumen",
  ];
  const rows = [
    {
      id: 1,
      company: "Empresa 1",
      project: "Proyecto 1",
      service: "Servicio 1",
      createdBy: "Usuario 1",
      type: "Tipo 1",
      status: "Estado 1",
      summary: "Resumen 1",
    },
    {
      id: 2,
      company: "Empresa 2",
      project: "Proyecto 2",
      service: "Servicio 2",
      createdBy: "Usuario 2",
      type: "Tipo 2",
      status: "Estado 2",
      summary: "Resumen 2",
    },
    {
      id: 3,
      company: "Empresa 3",
      project: "Proyecto 3",
      service: "Servicio 3",
      createdBy: "Usuario 3",
      type: "Tipo 3",
      status: "Estado 3",
      summary: "Resumen 3",
    },
  ];

  return (
    <Card>
      <Table titles={titles}>
        {rows.map((row, index) => (
          <Row
            key={index}
            columns={[
              row.id,
              row.company,
              row.project,
              row.service,
              row.createdBy,
              row.type,
              row.status,
              row.summary,
            ]}
          />
        ))}
      </Table>
    </Card>
  );
}
