const { toast } = require("sonner");
const { supabase } = require("utils/SupabaseClient");

// Obtener detalles de un usuario

export async function getUserDetails(user, details) {
  let selectInput = "*";

  switch (details) {
    case "ids":
      selectInput = "company_id, role_id";
      break;
    case "basic":
      selectInput =
        "company_id(company_name), first_name, last_name, phone, additional_phone, email, role_id(role_name) created_by(email), modified_by(email)";
      break;
    case "projects":
      selectInput =
        "projects_users!user_id(project_id, project: project_id(project_name))";
      break;
    case "tickets":
      selectInput = "tickets!created_by(id)";
      break;
    case "all":
      selectInput = "*";
      break;
    default:
      selectInput = "*";
  }
  const { data, error } = await supabase
    .from("users")
    .select(selectInput)
    .eq("id", user);
  if (error) {
    toast.error("Error al obtener el detalle del usuario");
  }
  return data[0];
}

// Obtener detalles de un proyecto

export async function getProjectDetails(project, details) {
  let selectInput = "*";

  switch (details) {
    case "ids":
      selectInput = "id, company_id, manager_id, created_by, modified_by";
      break;
    case "basic":
      selectInput = `id, project_name, code, description, company_id(company_name), manager_id(email), created_by(email), modified_by(email)`;
      break;
    case "services":
      selectInput = `id,  projects_services(services: enum_field_items(id, item_value))`;
      break;
    case "users":
      selectInput = "id, projects_users(user_id)";
      break;
    case "tickets":
      selectInput = "id, tickets(id)";
      break;
    case "all":
      selectInput = "*";
      break;
    default:
      selectInput = "*";
  }
  const { data, error } = await supabase
    .from("projects")
    .select(selectInput)
    .eq("id", project);
  if (error) {
    toast.error("Error al obtener el detalle del proyecto");
  }
  return data[0];
}

// Obtener detalle de un ticket

export async function getTicketDetails(ticket, details) {
  let selectInput = "*";

  switch (details) {
    case "ids":
      selectInput =
        "id,project_id, type_id, assigned_to, status_id, created_by, modified_by";
      break;
    case "basic":
      selectInput = `id, title, description, project_id(project_name), type_id(type_name), service_id(item_value),
                     assigned_to(email), status_id(status_name), created_by(email), modified_by(email)`;
      break;
    case "fields":
      selectInput =
        "id, ticket_fields(field_definition_id(id, field_name), field_value)";
      break;
    case "all":
      selectInput = "*, ticket_fields(field_definition_id, field_value)";
      break;
    default:
      selectInput = "*";
  }

  const { data, error } = await supabase
    .from("tickets")
    .select(selectInput)
    .eq("id", ticket);
  if (error) {
    toast.error("Error al obtener el detalle del ticket");
  }
  return data[0];
}

// Obtener detalle de una empresa

export async function getOrganizationDetails(organization, details) {
  let selectInput = "*";

  switch (details) {
    case "basic":
      selectInput = `id, company_name, nit, address(project_name), industry, created_by(email), modified_by(email)`;
      break;
    case "users":
      selectInput =
        "id, users!company_id(id, first_name, last_name, email, role_id(id, role_name))";
      break;
    case "projects":
      selectInput = "id, proejcts(id, project_name)";
      break;
    case "all":
      selectInput = "*";
      break;
    default:
      selectInput = "*";
  }

  const { data, error } = await supabase
    .from("companies")
    .select(selectInput)
    .eq("id", organization);
  if (error) {
    toast.error("Error al obtener el detalle de la empresa");
  }
  return data[0];
}

// Obtener todos los proyectos

export async function getAllProjects() {
  const { data, error } = await supabase.from("projects").select("*");
  if (error) {
    toast.error("Error obteniendo proyectos", error);
    return [];
  }
  return data;
}

// Obtener todos los tickets del usuario actual

export async function getAllUserTickets({ loggedUserId }) {
  const projects = await getUserDetails(loggedUserId, "projects");

  const { data, error } = await supabase
    .from("tickets")
    .select(
      "id, title, description, type_id, type: type_id(type_name), status_id, status: status_id(status_name), service_id(item_value)"
    )
    .in(
      "project_id",
      projects.projects_users.map((project) => project.project_id)
    );
  if (error) {
    toast.error("Error obteniendo los tickets", error);
    return [];
  }
  return data;
}

// Obtener todos los tickets de un usuario

export async function getAllUserProjects(userId) {
  const projects = await getUserDetails(userId, "projects");

  const { data, error } = await supabase
    .from("projects")
    .select(
      "id, project_name, description, projects_services(services: enum_field_items(item_value))"
    )
    .in(
      "id",
      projects.projects_users.map((project) => project.project_id)
    );
  if (error) {
    toast.error("Error obteniendo los tickets", error);
    return [];
  }
  return data;
}

// Obtener los tipos de ticket

export async function getTicketTypes() {
  const { data, error } = await supabase
    .from("ticket_types")
    .select("id, type_name");
  if (error) {
    toast.error("Error obteniendo el tipo de ticket", error);
    return [];
  }
  return data;
}

// Items de los desplgables de los formularios de tickets

export async function getEnumFieldItems(field) {
  const { data, error } = await supabase
    .from("enum_field_items")
    .select("id,item_value, field_definition_id")
    .eq("field_definition_id", field);
  if (error) {
    toast.error("Error obteniendo los items del desplegable", error);
    return [];
  }
  return data;
}

// obtener todos los estados de los tickets

export async function getStatusValues() {
  const { data, error } = await supabase
    .from("ticket_statuses")
    .select("id, status_name");
  if (error) {
    toast.error("Error obteniendo los items del desplegable", error);
    return [];
  }
  return data;
}
