import { useEffect, useState } from "react";

import { getColor, getIconColor, types } from "../../../utils/Colors";
import { getAllUserTickets} from "hooks/commonData";

import { Pagination } from "components/ServiceDesk/Pagination";

import { Link } from "react-router-dom";
import { getUserData } from "utils/SupabaseClient";

export function Issues() {

  const [userId, setUserId] = useState("");
  const [ticketDetail, setTicketDetail] = useState([]);



  const consultarTickets = userId !== "";

  useEffect(() => {
    getUserData().then((data) => {
      setUserId(data.id);
    });
  }, []);



  useEffect(() => {
    if (consultarTickets) {
      getAllUserTickets({loggedUserId: userId}).then((data)=>{
        setTicketDetail(data); 
      });
    }
  }, [consultarTickets]);
 



  return (
    <section>
      <header class="bg-white space-y-4 p-4 sm:px-8 sm:py-6 lg:p-4 xl:px-8 xl:py-6">
        <div class="flex items-center justify-between">
          <h2 class="font-semibold text-slate-900">Incidencias</h2>
          <Link
            to="/requests"
            class="hover:bg-sky-600 group flex items-center rounded-md bg-sky-700 text-white text-sm font-medium pl-2 pr-3 py-2 shadow-sm"
          >
            <svg
              width="20"
              height="20"
              fill="currentColor"
              class="mr-2"
              aria-hidden="true"
            >
              <path d="M10 5a1 1 0 0 1 1 1v3h3a1 1 0 1 1 0 2h-3v3a1 1 0 1 1-2 0v-3H6a1 1 0 1 1 0-2h3V6a1 1 0 0 1 1-1Z" />
            </svg>
            Nuevo
          </Link>
        </div> 
      </header>

      <ul role="list" className="divide-y divide-gray-100 px-8">
        {ticketDetail.map((ticket) => {
          const service = ticket.service_id.item_value;
          const color = getColor(ticket.status_id); 
          const iconColor = getIconColor(ticket.type_id);

          return (
            <Link key={ticket.id} to={`${ticket.type_id}/${ticket.id}`}>
              <li className="flex justify-between gap-x-6 py-5 hover:bg-slate-50">
                <div className="flex min-w-0 gap-x-4">
                  <div className="min-w-0 flex-auto">
                    <p className="text-sm font-semibold leading-6 text-gray-900 flex items-center">
                      <span
                        className={`flex items-center justify-center h-5 w-5 rounded mr-2 ${iconColor} text-white`}
                      >
                        {types[ticket.type_id]}
                      </span>
                      {ticket.title}
                    </p>
                    <p className="mt-1 truncate text-xs leading-5 text-gray-500">
                      {ticket.description}
                    </p>
                  </div>
                </div>
                <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
                  <p className="text-sm leading-6 text-gray-900">
                    {service}
                  </p>
                  <div className="mt-1 flex items-center gap-x-1.5">
                    <div className="flex-none rounded-full bg-emerald-500/20 p-1">
                      <div className={`h-1.5 w-1.5 rounded-full ${color}`} />
                    </div>
                    <p className="text-xs leading-5 text-gray-500">
                      {ticket.status.status_name}
                    </p>
                  </div>
                </div>
              </li>
            </Link>
          );
        })}
      </ul>
      <Pagination size={ticketDetail.length} />
    </section>
  );
}
