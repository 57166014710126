import { toast } from "sonner";

import { createCustumerTicket } from "api/tickets/route";
import { supabase } from "utils/SupabaseClient";



// guardar una solicitud

export async function saveCustumerRequest({
  ticketTypeId,
  projectId,
  summary,
  description,
  serviceId,
  attachment,
}) {

    const dataTicket = await createCustumerTicket({
      ticketTypeId,
      serviceId,
      projectId,
      summary,
      description
    })
    

  const { data, error } = await supabase
    .from("ticket_fields")
    .insert([
      {
        ticket_id: dataTicket[0],
        field_definition_id: 11,
        field_value: attachment,
        created_by: dataTicket[1],
        modified_by:  dataTicket[1]
      },
    ])
  if (error) {
    toast.error(`Error al guardar la solicitud`);
  }
}
