import { Form } from "../../../../components/ServiceDesk/Forms";
import { Text } from "../../../../components/ServiceDesk/Text";
import { Select } from "../../../../components/ServiceDesk/Select";
import { TextArea } from "../../../../components/ServiceDesk/TextArea";

import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getUserDetails, getProjectDetails } from "hooks/commonData";

import { saveCustumerRequirement } from "api/requeriments/route";

import { toast } from "sonner";
import { getUserData } from "utils/SupabaseClient";

export function Requeriments({ ticketTypeId }) {
  const navigate = useNavigate();

  const [user, setUser] = useState("");
  const [enumServices, setEnumServices] = useState([]);
  const [enumProjects, setEnumProjects] = useState([]);

  const consultarProyectos = user !== "";


  useEffect(() => {
    getUserData().then((data) => {
      setUser(data.id);
    });
  }, []);

  useEffect(() => {
    if (consultarProyectos) {
      getUserDetails(user, "projects").then((data) => {
        setEnumProjects(data.projects_users);
      });
    }
  }, [consultarProyectos]);

  const [selectedService, setSelectedService] = useState(-1);
  const [selectedProject, setSelectedProject] = useState(-1);
  const [summary, setSummary] = useState("");
  const [justify, setJustify] = useState("");
  const [profile, setProfile] = useState("");
  const [objective, setObjective] = useState("");
  const [benefit, setBenefit] = useState("");
  const [additionalInfo, setAdditionalInfo] = useState("");


  useEffect(() => {
    if (selectedProject != -1 ) {
      getProjectDetails(selectedProject, "services").then((data) => {
        setEnumServices(data.projects_services);    
      });
    }
    else {setEnumServices([])}
  }, [selectedProject]);


  const handleSave = async () => {
    if (selectedService === -1) {
      toast.error("Por favor selecciona un servicio.");
      return;
    }

    if (selectedProject === -1) {
      toast.error("Por favor selecciona un proyecto.");
      return;
    }

    if (summary === "") {
      toast.error("Por favor escribe un resumen.");
      return;
    }

    if (justify === "") {
      toast.error("Por favor escribe una justificación.");
      return;
    }

    if (profile === "") {
      toast.error("Por favor escribe un perfil.");
      return;
    }

    if (objective === "") {
      toast.error("Por favor escribe un objetivo.");
      return;
    }

    if (benefit === "") {
      toast.error("Por favor escribe un beneficio.");
      return;
    }

    await saveCustumerRequirement({
      ticketTypeId,
      projectId: selectedProject,
      summary,
      description: "No aplica descripción",
      serviceId: selectedService,
      justify,
      profile,
      objective,
      benefit,
      additionalInfo,
    });

    toast.success("Requerimiento creado exitosamente.");

    navigate("/issues");
  };

  return (
    <div className="mx-auto max-w-7xl p-6 lg:p-8">
      <Form
        title={"Requerimientos"}
        description={"Describe tu requerimiento."}
        onClick={handleSave}
      >
        <Select
          title={"Proyecto"}
          field={"project"}
          onChange={setSelectedProject}
          options={enumProjects.map((proyecto) => ({
            value: proyecto.project_id,
            name: proyecto.project.project_name,
          }))}
        />
        <Select
          title={"Servicio"}
          field={"service"}
          onChange={setSelectedService}
          options={enumServices.map((item) => ({
            value: item.services.id,
            name: item.services.item_value,
          }))}
        />
        <Text title="Resumen" field={"summary"} onChange={setSummary} />
        <TextArea
          title={"Justificación del proyecto"}
          field={"justify"}
          onChange={setJustify}
          description={"Para que se va a realizar el proyecto."}
        />
        <TextArea
          title={"Como (perfil)"}
          field={"profile"}
          onChange={setProfile}
          description={"Perfil de la persona que va a usar la herramienta."}
        />
        <TextArea
          title={"Quiero (objetivo"}
          field={"objective"}
          onChange={setObjective}
          description={
            "Describe la intención, no las funciones que usan. ¿Qué es lo que están intentando lograr realmente?"
          }
        />
        <TextArea
          title={"Para (beneficio)"}
          field={"benefit"}
          onChange={setBenefit}
          description={"¿Qué beneficio se espera obtener?"}
        />
        <TextArea
          field={"Aditional info"}
          onChange={setAdditionalInfo}
          title={"Información adicional"}
        />
      </Form>
    </div>
  );
}
