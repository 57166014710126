import { toast } from "sonner";
import { getUserData, supabase } from "utils/SupabaseClient";

export async function createCustumerTicket({
  ticketTypeId,
  serviceId,
  projectId,
  summary,
  description
})
{

  const user = await getUserData();

   const { data, error } = await supabase
    .from("tickets")
    .insert([
      {
        project_id: projectId,
        type_id: ticketTypeId,
        service_id: serviceId,
        title: summary,
        description: description,
        created_by: user.id,
        modified_by:  user.id
      },
    ])
    .select();
  if (error) {
    toast.error(`Error al guardar la incidencia`);
  }
  return [data[0].id,  data[0].created_by]

}
