import { useState, useEffect } from "react";

import { toast } from "sonner";
import { App } from "../modules/App";
import { ServiceDesk } from "./service-desk";
import { LayoutPMO } from "./service-desk/pmo/LayoutPMO";

import { supabase, getUserData } from "utils/SupabaseClient";

export function Layout() {
  const [user, setUser] = useState();
  const [rol, setRol] = useState(-1);

  useEffect(() => {
    async function getUser() {
      const userData = await getUserData();

      const { data, error } = await supabase
        .from("users")
        .select("role_id, first_name, last_name")
        .eq("id", `${userData.id}`);
      if (!error) {
        setUser(data[0]);
        setRol(data[0].role_id);
        toast.success(`Bienvenid@ ${data[0].first_name} ${data[0].last_name}!`);
      }
    }

    getUser();
  }, []);

  if (rol === 1) {
    return (
      <App
        nombre={user ? user.first_name : "...cargando"}
        apellido={user ? user.last_name : "...cargando"}
        email={user ? user.email : "...cargando"}
      />
    );
  } else if (rol === 2) {
    return <ServiceDesk />;
  } else if (rol === 3) {
    return <LayoutPMO />;
  }
}
