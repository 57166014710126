import { Form } from "../../../../components/ServiceDesk/Forms";
import { Text } from "../../../../components/ServiceDesk/Text";
import { Select } from "../../../../components/ServiceDesk/Select";
import { TextArea } from "../../../../components/ServiceDesk/TextArea";
import { Files } from "../../../../components/ServiceDesk/Files";

import { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { toast } from "sonner";

import { getUserDetails, getEnumFieldItems, getProjectDetails } from "hooks/commonData";
import { saveCustumerProblem } from "api/problems/route";
import { getUserData } from "utils/SupabaseClient";

export function Problems({ ticketTypeId }) {
  const navigate = useNavigate();
  

  const [user, setUser] = useState("");
  const [enumServices, setEnumServices] = useState([]);
  const [enumProjects, setEnumProjects] = useState([]);
  const [enumImpacts, setEnumImpacts] = useState([]);

  const consultarProyectos = user !== "";

  useEffect(() => {
    getUserData().then((data) => {
      setUser(data.id);
    });
    getEnumFieldItems(7).then((data) => {
      setEnumImpacts(data);
    });
  }, []);


  useEffect(() => {
    if (consultarProyectos) {
      getUserDetails(user, "projects").then((data) => {
        setEnumProjects(data.projects_users);
      });
    }
  }, [consultarProyectos]);

  const [selectedService, setSelectedService] = useState(-1);
  const [selectedProject, setSelectedProject] = useState(-1);
  const [summary, setSummary] = useState("");
  const [description, setDescription] = useState("");
  const [selectedImpact, setSelectedImpact] = useState(-1);
  const [reason, setReason] = useState("");
  const [solution, setSolution] = useState("");
  const [steps, setSteps] = useState("");


  useEffect(() => {
    if (selectedProject != -1 ) {
      getProjectDetails(selectedProject, "services").then((data) => {
        setEnumServices(data.projects_services);    
      });
    }
    else {setEnumServices([])}
  }, [selectedProject]);


  const handlesave = async () => {
    if (
      !selectedService === -1 ||
      !selectedProject === -1 ||
      !summary ||
      !description ||
      selectedImpact === -1 ||
      !reason ||
      !solution ||
      !steps
    ) {
      toast.error("Por favor llene todos los campos");
      return;
    }

    await saveCustumerProblem({
      ticketTypeId,
      projectId: selectedProject,
      summary,
      description,
      serviceId: selectedService,
      impactValue: selectedImpact,
      reason,
      solution,
      steps,
      attachment: "Adjuntos",
    });

    toast.success("Problema creado exitosamente");
    navigate("/issues");
  };

  return (
    <div className="mx-auto max-w-7xl p-6 lg:p-8">
      <Form
        title={"Problemas"}
        description={"Por favor describe el problema o falla masiva."}
        onClick={handlesave}
      >
        <Select
          title={"Proyecto"}
          field={"project"}
          onChange={setSelectedProject}
          options={enumProjects.map((proyecto) => ({
            value: proyecto.project_id,
            name: proyecto.project.project_name,
          }))}
        />
        <Select
          title={"Servicio"}
          field={"service"}
          onChange={setSelectedService}
          options={enumServices.map((item) => ({
            value: item.services.id,
            name: item.services.item_value,
          }))}
        />
        <Text title="Resumen" field={"summary"} onChange={setSummary} />
        <TextArea
          title={"Descripción"}
          onChange={setDescription}
          description={"Por favor describemos el error."}
        />
        <Select
          title={"Impacto"}
          onChange={setSelectedImpact}
          options={enumImpacts.map((impacto) => ({
            value: impacto.item_value,
            name: impacto.item_value,
          }))}
        />
        <TextArea
          title={"Causa principal"}
          onChange={setReason}
          description={"Motivo por el cual se genera el problema."}
        />
        <TextArea
          title={"Solución"}
          onChange={setSolution}
          description={"Plan a ejecutar para solventar el problema."}
        />
        <TextArea
          title={"Paso a paso de como ocurre el error"}
          onChange={setSteps}
          description={
            "Texto largo, descripción del paso a paso del proceos que realizo para que se le generara el error."
          }
        />
        <Files
          title={"Documentos adicionales"}
          message={"PNG, JPG, GIF up to 10MB"}
        />
      </Form>
    </div>
  );
}
