import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { ViewRequestUI } from "./ViewRequestUI";
import { getTicketDetails } from "hooks/commonData";

export function ViewRequest() {
  const { issue } = useParams();
  const [message, setMessage] = useState("");

  //TODO: Replace this mock data with the real data
  const comments = [
    {
      user: "Usuario",
      date: "Fecha",
      comment: "Comentario",
    },
    {
      user: "Usuario",
      date: "Fecha",
      comment: "Comentario",
    },
  ];

  //TODO: Implement S3 integration to get the real documents
  const documents = [
    {
      name: "Document.pdf",
      size: "1.5MB",
    },
    {
      name: "Document2.pdf",
      size: "2.5MB",
    },
  ];

  const [ticketBasic, setTicketBasic] = useState();
  const [ticketFields, setTicketFields] = useState([]);

  useEffect(() => {
    getTicketDetails(issue, "basic").then((data) => {
      setTicketBasic(data);
    });
    getTicketDetails(issue, "fields").then((data) => {
      setTicketFields(data.ticket_fields);
    });
  }, []);

  const handleSaveComment = async () => {
    //await saveComment(message, issue);
  };

  if (!ticketBasic || !ticketFields) {
    return <p>Cargando...</p>;
  } else if (ticketBasic || ticketFields) {
    return (
      <ViewRequestUI
        summary={ticketBasic.title}
        description={ticketBasic.description}
        createdBy={ticketBasic.created_by.email}
        project={ticketBasic.project_id.project_name}
        status={ticketBasic.status_id.status_name}
        service={ticketBasic.service_id.item_value}
        documents={documents}
        comments={comments}
        setMessage={setMessage}
        saveComment={handleSaveComment}
      />
    );
  }
}
