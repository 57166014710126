export function Comment({ children, onClick }) {
  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <form
      className="border border-gray-200 p-4 rounded-md"
      onSubmit={handleSubmit}
    >
      {children}
      <div className="flex items-center justify-end gap-x-6">
        <button
          type="submit"
          onClick={onClick}
          className="rounded-md bg-sky-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-700"
        >
          Guardar
        </button>
      </div>
    </form>
  );
}
