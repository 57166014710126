import { types } from "utils/Colors";

export function ViewIssueHeader({ issueType, text, summary }) {
  return (
    <div className="px-4 sm:px-0">
      <h3 className="flex items-center text-base font-semibold leading-7 text-gray-900">
        <span className="flex items-center justify-center h-5 w-5 rounded mr-2 bg-blue-500 text-white">
          {types[issueType]}
        </span>
        {summary}
      </h3>
      <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">{text}</p>
    </div>
  );
}
