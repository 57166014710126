import { ViewIssueField } from "components/ServiceDesk/ViewIssueField";
import { ViewIssueHeader } from "components/ServiceDesk/ViewIssueHeader";
import { ViewIssueFieldStatus } from "components/ServiceDesk/ViewIssueFieldStatus";
import { ViewIssueDocuments } from "components/ServiceDesk/ViewIssueDocuments";

export function ViewProblemUI({
  summary,
  description,
  createdBy,
  project,
  status,
  service,
  impact,
  reason,
  solution,
  steps,
  documents,
}) 
{
  return (
    <div className="space-y-4 p-4 sm:px-8 sm:py-6 lg:p-4 xl:px-8 xl:py-6">
      <ViewIssueHeader
        summary={summary}
        issueType={2}
        text={"Información detallada de la solicitud."}
      />
      <div className="mt-6 border-t border-gray-100">
        <dl className="divide-y divide-gray-100">
          <ViewIssueFieldStatus label="Estado" statusId={status} />
          <ViewIssueField label="Creado por" value={createdBy} />
          <ViewIssueField label="Proyecto" value={project} />
          <ViewIssueField label="Servicio" value={service} />
          <ViewIssueField label="Descripción" value={description} />
          <ViewIssueField label="Impacto" value={impact} />
          <ViewIssueField label="Causa principal" value={reason} />
          <ViewIssueField label="Solución" value={solution} />
          <ViewIssueField label="Paso a paso de como ocurrió el error" value={steps} />
          <ViewIssueDocuments documents={documents} />
        </dl>
      </div>
    </div>
  );
}
