function Option({ value, name }) {
  return <option value={value}>{name}</option>;
}

export function Select({ title, options, field, onChange }) {
  const handleChange = (e) => {
    onChange(e.target.value);
  };

  return (
    <div className="sm:col-span-6">
      <label
        htmlFor={field}
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        {title}
      </label>
      <div className="mt-2">
        <select
          id={field}
          name={field}
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-sky-700 sm:max-w-xs sm:text-sm sm:leading-6"
          onChange={handleChange}
        >
          <Option value={-1} name={"Selecciona una opción"} />
          {options.map((option) => (
            <Option
              key={option.value}
              value={option.value}
              name={option.name}
            />
          ))}
        </select>
      </div>
    </div>
  );
}
